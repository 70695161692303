import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { remove_stripe_card } from "src/DAL/Payment/Payment";
import { useSnackbar } from "notistack";
import CustomConfirmationShop from "src/pages/DDShop/CustomConfirmationShop";

const ShowCard = ({ inputs, setInputs }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [cardRemovePopup, setCardRemovePopup] = useState(false);
  const [loadingPopUp, setloadingPopUp] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };

  const handleNavigate = () => {
    setInputs({ cvc: "", expiry: "", focus: "", name: "", number: "" });
    navigate(`/change-card`);
  };

  const openCardRemovePopup = () => {
    setCardRemovePopup(true);
  };

  const handleCardRemove = async () => {
    setloadingPopUp(true);
    const result = await remove_stripe_card();
    if (result.code == 200) {
      setInputs({ cvc: "", expiry: "", focus: "", name: "", number: "" });
      enqueueSnackbar(result.message, { variant: "success" });
      setloadingPopUp(false);
      setCardRemovePopup(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setloadingPopUp(false);
      setCardRemovePopup(false);
    }
  };

  return (
    <div className="container">
      <h2>Card Details</h2>
      <div className="row mt-5">
        <div className="col-md-4 how-img mb-4 update-stripe-card">
          <Cards
            cvc={inputs.cvc}
            expiry={inputs.expiry}
            focused={inputs.focus}
            name={inputs.name}
            number={inputs.number}
            issuer={inputs.type}
            preview={true}
            callback={(e) => ({
              issuer: "visa",
              maxLength: 19,
            })}
          />
        </div>
        <div className="col-md-8">
          <form className="text-center mt-1 mx-auto w-100">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="number"
                  value={inputs.number}
                  label="Card Number"
                  disabled
                  variant="outlined"
                  placeholder="Card Number"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="name"
                  disabled
                  value={inputs.name}
                  label="Card Holder Name"
                  variant="outlined"
                  placeholder="Card Holder Name"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="expiry"
                  disabled
                  value={inputs.expiry}
                  label="Valid Thru"
                  variant="outlined"
                  placeholder="Expiry"
                  fullWidth
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="cvc"
                  disabled
                  value={inputs.cvc}
                  label="Cvc"
                  variant="outlined"
                  placeholder="Cvc"
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
            </div>
          </form>
          <div className="col-12 text-end mt-4 ">
            <button
              className="small-contained-button me-2"
              onClick={openCardRemovePopup}
              style={{ color: "#FF0000", border: "1px solid #FF0000" }}
            >
              Remove Card
            </button>

            <button className="small-contained-button" onClick={handleNavigate}>
              Change Card
            </button>
          </div>
        </div>

        <CustomConfirmationShop
          open={cardRemovePopup}
          setOpen={setCardRemovePopup}
          setloadingPopUp={setloadingPopUp}
          loadingPopUp={loadingPopUp}
          title="Are you sure you want to remove this card?"
          description="If you remove this card, all the attached cards will also be permanently deleted. This cannot be undone."
          handleAgree={handleCardRemove}
        />
      </div>
    </div>
  );
};
export default ShowCard;
