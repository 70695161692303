import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContentSetting } from 'src/Hooks/ContentContext/ContentSettingState';

export default function LiveStream() {
    const { userInfo } = useContentSetting();
    const [streamApi, setStreamApi] = useState({})
    const navigate = useNavigate()
    const handleEndCall = () => {
        console.log('call ending')
        streamApi.executeCommand('hangup');
        navigate(`/thesource`)
    }

    useEffect(() => {
        const domain = 'meet.jit.si';
        const options = {
            roomName: 'JitsiMeetAPIExample',
            width: "100%",
            height: 700,
            parentNode: document.querySelector('#meet'),
            lang: 'en',
            configOverwrite: {
                enableJoin: false,
                disableInviteFunctions: true,
                toolbarButtons: ['microphone', 'camera', "participants-pane"],
                prejoinConfig: {
                    enabled: false,
                },
                startWithAudioMuted: false,
                hideConferenceSubject: true,
            },
            userInfo: {
                email: userInfo.email,
                displayName: userInfo.first_name
            }
        };
        const api = new JitsiMeetExternalAPI(domain, options);
        api.executeCommand('toggleFilmStrip');
        setStreamApi(api)
    }, [])
    return (
        <>
            <div id='meet'></div>
            <button onClick={handleEndCall}>End</button>
        </>
    )
}
