import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UnSubscribe_and_subscribe_api } from "src/DAL/ShopApi/ApiShop";

const UnSubscribe = () => {
  const [selectedValue, setSelectedValue] = useState("1");
  const [isload, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const textParam = queryParams.get("receiver");
  const emailType = queryParams.get("email_type");
  console.log(emailType, "emailTypeemailTypeemailType");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let action = "unsubscribe";
    if (selectedValue == "4") {
      action = "subscribe";
    }
    let postData = {
      receiver: emailType == "all" ? email : textParam,
      email_type: emailType,
      action: action,
    };
    const result = await UnSubscribe_and_subscribe_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmitted(true);
      sessionStorage.setItem("submitted", true);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="page-container">
        {!isSubmitted ? (
          <div className="page-box card p-3">
            <form onSubmit={handleSubmit}>
              <h4
                style={{
                  textTransform: "capitalize",
                  fontSize: "22px",
                  textAlign: "center",
                  color: "#f6bd4b",
                }}
              >
                you have been unsubscribed
              </h4>
              <div style={{ borderTop: "1px solid #474747" }}></div>
              <p className="mt-2 mb-2 note">
                You have been unsubscribed from theses emails. please take a
                moment and let us know why you unsubscribed.
              </p>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedValue}
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label="I no longer wish to receive these emails"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio size="small" />}
                      label="The emails are no longer for content that I originally signed up for"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio size="small" />}
                      label="I never gave my permission to receive these emails, please report this as abuse"
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio size="small" />}
                      label="I unsubscribed by accident! Please add me back to this list"
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio size="small" />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                {emailType == "all" && (
                  <div className="mt-2 mb-2 col-8">
                    <TextField
                      label="Please Enter Email From you want to Unsubscribe"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                      type="email"
                      size="small"
                    />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end w-100">
                <button className="small-contained-button add-to-cart-button mt-1 mb-2">
                  {isload ? "please Wait..." : "Share Feedback"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className=" card p-3" style={{ width: "50%" }}>
            <h4
              style={{
                textTransform: "capitalize",
                fontSize: "22px",
                textAlign: "center",
                color: "#f6bd4b",
              }}
            >
              you have been unsubscribed
            </h4>
            <div style={{ borderTop: "1px solid #474747" }}></div>
            <p className="mt-2 mb-2 note">
              You have been unsubscribed from theses emails. please take a
              moment and let us know why you unsubscribed.
            </p>

            {selectedValue == "4" ? (
              <p className="tanks-text">
                You have been resubscribed to this list.
              </p>
            ) : (
              <p className="tanks-text">
                Thanks for providing your feedback on why you unsubscribed.
              </p>
            )}
            {selectedValue !== "4" && (
              <p style={{ color: "#c2c2c2" }}>
                You have been unsubscribed from these emails.
              </p>
            )}

            {/* <div className="d-flex justify-content-center w-100">
              <button
                className="small-contained-button add-to-cart-button  mb-2"
                onClick={() => navigate("/")}
              >
                Continue To The Source
              </button>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default UnSubscribe;
