import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const Shop_list = async (loadMore) => {
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Shop_Catagory_list = async (loadMore) => {
  const requestObj = {
    path: `api/product_category/product_category_list_for_client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Shop_list_detail = async (id) => {
  const requestObj = {
    path: `api/dynamite_product/dynamite_product_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
