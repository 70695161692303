import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TimeZones, get_address, handleGetCountry } from "src/utils/constants";
import { EditProfileApi, VerifyAddressApi } from "src/DAL/Profile/Profile";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import countryList from "react-select-country-list";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PhoneInput from "react-phone-input-2";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
const Input = styled("input")({
  display: "none",
});
TimeZones.sort();

function VerifyAddress({ setIsAddressVerifiedPopup, addressVerificationDate }) {
  const { handleUserInfo, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [open, setOpen] = useState(false);
  const [phoneData, setPhoneData] = useState();
  const [inputValue, setInputValue] = useState("");
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [memberData, setMemberData] = useState({
    street: "",
    street2: "",
    city: "",
    zip_code: "",
    state: "",
    email: "",
    country: {
      value: "IE",
      label: "Ireland",
    },
    is_postal_address_same: true,
  });
  const [postalData, setPostalData] = useState({
    street: "",
    street2: "",
    city: "",
    zip_code: "",
    state: "",
    country: {
      value: "IE",
      label: "Ireland",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeDate = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        dob: event.$d,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let postData = {
      street: memberData.street,
      city: memberData.city,
      zip_code: memberData.zip_code,
      state: memberData.state,
      country: memberData.country ? memberData.country.value : "",
      street2: memberData.street2,
      is_postal_address_same: memberData.is_postal_address_same,
    };
    let postal_addess_info = {
      street: postalData.street,
      street2: postalData.street2,
      city: postalData.city,
      zip_code: postalData.zip_code,
      state: postalData.state,
      country: postalData.country ? postalData.country.value : "",
    };
    if (memberData.is_postal_address_same === false) {
      postData.postal_addess_info = postal_addess_info;
    }
    const result = await VerifyAddressApi(postData);
    if (result.code === 200) {
      handleUserInfo(result.member);
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      setIsAddressVerifiedPopup(true);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsSubmitting(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeCountry = (value) => {
    setMemberData((values) => ({ ...values, country: value }));
  };
  const handleChangePostalCountry = (value) => {
    setPostalData((values) => ({ ...values, country: value }));
  };

  const handleChangePhone = (value, selected) => {
    let find_country = options.find((c) => c.label == selected.name);
    let selected_values = {
      contact_number: value,
    };
    if (!Boolean(get_address(userInfo.country))) {
      selected_values.country = find_country;
    }
    setMemberData((values) => ({ ...values, ...selected_values }));
  };

  const handleChangePostal = (event) => {
    const { name, value } = event.target;
    setPostalData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeCheckbox = (event) => {
    let is_checked = event.target.checked;
    setMemberData((old) => ({ ...old, is_postal_address_same: is_checked }));
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  let get_country = (data) => {
    let find_country = {};
    if (userInfo.country) {
      find_country = options.find((c) => c.value == data.country);
    } else {
      find_country = options.find((c) => c.value == handleGetCountry());
    }
    return find_country;
  };

  let address_obj = {
    street: get_address(userInfo.street),
    street2: get_address(userInfo.street2),
    city: get_address(userInfo.city),
    state: get_address(userInfo.state),
    zip_code: get_address(userInfo.zip_code),
    vat_number: get_address(userInfo.vat_number),
    member_biography: get_address(userInfo.member_biography),
    country: get_country(userInfo),
  };

  const handleFormatData = () => {
    setMemberData({ ...userInfo, ...address_obj });
    setPhoneData(userInfo.contact_number);
    setTimeZoneValue(userInfo.time_zone);
    setPostalData({
      ...userInfo.postal_addess_info,
      country: get_country(userInfo.postal_addess_info),
    });
  };

  useEffect(() => {
    handleFormatData();
  }, []);

  return (
    <div className="container ">
      <form onSubmit={handleUpdate}>
        <div className="">
          <div className="row">
            {addressVerificationDate && (
              <div
                className="col-12 mt-2"
                dangerouslySetInnerHTML={{
                  __html: addressVerificationDate,
                }}
              />
            )}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4 select-box">
                <MUIAutocomplete
                  inputLabel="Country"
                  selectedOption={memberData.country}
                  setSelectedOption={handleChangeCountry}
                  optionsList={options}
                  autoComplete="new-password"
                  name="label"
                  size="small"
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  label="Town or City"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={memberData.city}
                  name="city"
                  required={true}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  label="State"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={memberData.state}
                  name="state"
                  onChange={(e) => handleChange(e)}
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  label="Street Address 1"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={memberData.street}
                  name="street"
                  onChange={(e) => handleChange(e)}
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  label="Street Address 2"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={memberData.street2}
                  name="street2"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  label="Area Code"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={memberData.zip_code}
                  name="zip_code"
                  onChange={(e) => handleChange(e)}
                  required={true}
                />
              </div>
            </div>

            <div className="col-12 mt-2">
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_postal_address_same"
                    checked={memberData.is_postal_address_same}
                    onChange={(e) => handleChangeCheckbox(e)}
                  />
                }
                label="Same Postal Address"
              />
            </div>
            {memberData.is_postal_address_same === false && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                  <MUIAutocomplete
                    inputLabel="Country"
                    selectedOption={postalData.country}
                    setSelectedOption={handleChangePostalCountry}
                    optionsList={options}
                    autoComplete="new-password"
                    name="label"
                    size="small"
                    required={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Town or City"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={postalData?.city}
                      name="city"
                      onChange={(e) => handleChangePostal(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={postalData?.state}
                      name="state"
                      onChange={(e) => handleChangePostal(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Street Address 1"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={postalData?.street}
                      name="street"
                      onChange={(e) => handleChangePostal(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Street Address 2"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={postalData?.street2}
                      name="street2"
                      onChange={(e) => handleChangePostal(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Area Code"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={postalData?.zip_code}
                      name="zip_code"
                      onChange={(e) => handleChangePostal(e)}
                      required={true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mt-1  mb-3 ms-auto text-end">
            <button className="small-contained-button mt-3 text-end">
              {isSubmitting ? "Please wait..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default VerifyAddress;
