import { OTSession, OTStreams, OTSubscriber } from "opentok-react";
import React from "react";

export default function ViewerLivestream() {
  const apiKey = "47745591";
  const sessionId =
    "1_MX40Nzc0NTU5MX5-MTY4OTIzNDc4OTgwNH42NSt1U2c2bmUzalFlK2wzWTAyRVVkWWl-UH5-";
  const token =
    "T1==cGFydG5lcl9pZD00Nzc0NTU5MSZzaWc9MjBmM2I3ZGE1ZGJhNTEwOTYxZTMwMWMzOThlMmM4NGExMGQwMGQ3YTpzZXNzaW9uX2lkPTFfTVg0ME56YzBOVFU1TVg1LU1UWTRPVEl6TkRjNE9UZ3dOSDQyTlN0MVUyYzJibVV6YWxGbEsyd3pXVEF5UlZWa1dXbC1VSDUtJmNyZWF0ZV90aW1lPTE2ODkyMzQ3OTEmbm9uY2U9MC45MjcwMDg0MzcwODM2MTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4OTMyMTE5MSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==";

  return (
    <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
      <OTStreams>
        <OTSubscriber />
      </OTStreams>
    </OTSession>
  );
}
