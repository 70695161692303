import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import {
  feed_survey_details_api,
  manage_survey_answer_api,
} from "src/DAL/Community/SurveyFeed";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function SurveyDetail(props) {
  const { selectedFeed, handleOptionResult } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { userInfo, adminTimeZone, socket } = useContentSetting();
  const [feedInfo, setFeedInfo] = useState({});
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDetail = async () => {
    const result = await feed_survey_details_api(selectedFeed._id);
    if (result.code === 200) {
      setFeedInfo(result.feed_obj);
      setSurveyQuestions(result.questions);
      setSelected(result.survey_selected_options);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAnswer = async (option, question) => {
    if (feedInfo.survey_info.survey_status == "expired") return;
    const postData = {
      feed_id: selectedFeed._id,
      option_id: option._id,
      question_id: question._id,
    };

    const result = await manage_survey_answer_api(postData);
    if (result.code === 200) {
      setFeedInfo(result.feed_obj);
      setSurveyQuestions(result.feed_obj.survey_info.questions);
      setSelected(result.feed_obj.survey_selected_options);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_survey_answered = (result) => {
    setSurveyQuestions(result.feed_obj.survey_info.questions);
  };

  const handle_survey_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedInfo((feed) => {
        feed.survey_info.survey_status = "expired";
        return feed;
      });
    }
  };

  useEffect(() => {
    handleDetail();
    socket.on("live_feed_room_reciever", (data) => {
      if (data.action === "survey_answered") {
        handle_survey_answered(data);
      } else if (data.action === "survey_expired") {
        handle_survey_expired(data);
      }
    });
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="poll-result-heading">Survey Statement</div>
      <div>{feedInfo?.description}</div>
      <div className="poll-result-heading mt-2">Expiration</div>
      <div className="poll-expiry-date">
        {`${
          feedInfo?.survey_info.survey_status === "expired"
            ? "Survey expired on"
            : ""
        } ${TIME_ZONE_CONVERSION(
          feedInfo?.survey_info?.expiry_date_time,
          " DD-MM-YYYY [at] hh:mm A",
          adminTimeZone,
          userInfo.time_zone
        )}`}
      </div>
      <div className="poll-result-heading mt-2">Survey Questions</div>
      {surveyQuestions?.length > 0 &&
        surveyQuestions.map((question) => {
          const total_votes = question.options.reduce(
            (accumulator, option) => accumulator + option.votes,
            0
          );
          return (
            <>
              <div className="poll-question-item-post mb-2">
                <div className="px-1 mb-1 question-statement">
                  {htmlDecode(question.question_statement)}
                </div>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {question.options.map((option) => {
                    let answered = selected?.find(
                      (ans) => ans._id == option._id
                    );
                    let vote_percentage =
                      total_votes > 0 ? (option.votes / total_votes) * 100 : 0;

                    let params = {
                      className: "p-1",
                      checked: Boolean(answered),
                      onClick: () => handleAnswer(option, question),
                    };

                    return (
                      <>
                        {feedInfo?.survey_info?.survey_status == "expired" ? (
                          <div className="ps-1">{option.text}</div>
                        ) : (
                          <FormControlLabel
                            value="other"
                            control={
                              question.is_multiple_allow ? (
                                <Checkbox {...params} />
                              ) : (
                                <Radio {...params} />
                              )
                            }
                            label={option.text}
                            className="ps-2"
                          />
                        )}

                        <div className="d-flex align-items-center mx-1">
                          <div className="text-muted">{`${Math.round(
                            vote_percentage
                          )}%`}</div>
                          <div className="poll-progress-bar m-1">
                            <div
                              className={`selected-progress-bar ${
                                feedInfo?.survey_info?.survey_status ==
                                "expired"
                                  ? "poll-expired"
                                  : ""
                              }`}
                              style={{ width: `${vote_percentage}%` }}
                            ></div>
                          </div>
                          <div className="text-muted">
                            {`${Math.abs(option.votes)} ${
                              option.votes > 1 ? "votes" : "vote"
                            } `}
                          </div>
                        </div>
                        {option?.answers && option?.answers.length > 0 && (
                          <div
                            className="d-flex justify-content-start cursor-pointer"
                            onClick={() => handleOptionResult(option)}
                          >
                            <AvatarGroup max={4} className="feed-poll-avatars">
                              {option.answers.map((item, index) => {
                                let name = item.user_info_action_by;
                                return (
                                  <Avatar
                                    key={index}
                                    alt={name?.name}
                                    src={s3baseUrl + name?.profile_image}
                                  />
                                );
                              })}
                            </AvatarGroup>
                          </div>
                        )}
                      </>
                    );
                  })}
                </RadioGroup>
              </div>
            </>
          );
        })}
    </>
  );
}
