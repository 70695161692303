import React, { useEffect } from "react";
import "react-credit-cards/es/styles-compiled.css";
import ShowCard from "./ShowCard";
import { get_default_card } from "src/DAL/Payment/Payment";
import { useState } from "react";
import StripeCardChange from "./CardProvider";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const formatCard = (card) => {
  const { userInfo } = useContentSetting();
  let month = card.exp_month < 10 ? "0" + card.exp_month : card.exp_month;

  return {
    cvc: "***",
    expiry: month + "/" + card.exp_year,
    name: card.name ? card.name : userInfo?.first_name,
    number: "************" + card.last4,
    type: card.brand,
  };
};

const PaymentForm = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    type: "",
  });

  const getCardsDetail = async () => {
    const result = await get_default_card();
    if (result.code == 200) {
      if (result.stripe_card.last4) {
        setInputs((old) => ({ ...old, ...formatCard(result.stripe_card) }));
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const updateCard = (stripe_card) => {
    setInputs((old) => ({ ...old, ...formatCard(stripe_card) }));
  };

  useEffect(() => {
    getCardsDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      {inputs.number ? (
        <ShowCard inputs={inputs} setInputs={setInputs} />
      ) : (
        <StripeCardChange inputs={inputs} updateCard={updateCard} />
      )}
    </div>
  );
};
export default PaymentForm;
