import { CircularProgress, Tooltip } from "@mui/material";
import { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { get_root_value } from "src/utils/domUtils";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_address,
  handleGetCountry,
  handle_open_browser,
} from "src/utils/constants";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import parsePhoneNumber from "libphonenumber-js";
import {
  PurchaseApi,
  PurchaseApiConfurm,
  dynamite_product_purchase_by_bank_api,
  get_shop_orders_detail,
} from "src/DAL/ShopApi/ApiShop";
import { useSnackbar } from "notistack";
import { _change_currency } from "src/DAL/GeneralAPIs/GeneralAPIs";
import { htmlDecode } from "src/utils/convertHtml";
import UserInformation from "./components/UserInformation";
import TaxRate from "./components/TaxRate";
import CustomDutyTax from "./components/CustomDutyTax";
import ProductsList from "./components/ProductsList";
import ThankYou from "./components/ThankYou";
import CurrencyExchange from "./components/CurrencyExchange";
import ApplyCoupon from "./components/ApplyCoupon";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  contact_number: "",
  zip_code: "",
  street: "",
  city: "",
  country: null,
  state: "",
  currency: "",
  save_in_profile: false,
  is_shipping_same_as_billing: true,
  coupon_info: null,
  coupon_code: "",
};

const SHIPPING_EMPTY = {
  street: "",
  city: "",
  state: "",
  zip_code: "",
  country: null,
};

const ShopCheckOut = () => {
  const {
    userInfo,
    bankCurrencies,
    cartElement,
    setCardElement,
    shopContentSettings,
    setUserInfo,
  } = useContentSetting();

  const navigate = useNavigate();
  const [inputs, setInputs] = useState(EMPTY_VALUES);
  const [billingObject, setBillingObject] = useState(SHIPPING_EMPTY);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(true);
  const [isBankPayment, setIsBankPayment] = useState(true);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedFireCountry, setSelectedFireCountry] = useState(null);
  const [phoneData, setPhoneData] = useState();
  const [customDutyTax, setCustomDutyTax] = useState(0);
  const [selectedTax, setSelectedTax] = useState({});
  const [taxCharges, setTaxCharges] = useState(0);
  const [checkValidity, setCheckValidity] = useState(false);
  const classes = useStyles();
  const elements = useElements();
  const { order_id } = useParams();

  const options = useMemo(() => countryList().getData(), []);
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();

  const calculateTotalPrice = () => {
    const total = selectedProducts.reduce((total, product) => {
      return total + (product.selected_quantity * product.price || 0);
    }, 0);
    let discount = 0;
    if (inputs.coupon_info) {
      if (inputs.coupon_info.amount_off) {
        discount = inputs.coupon_info.amount_off / 100;
      } else if (inputs.coupon_info.percent_off) {
        discount = (total * inputs.coupon_info.percent_off) / 100;
      }
    }
    discount = Math.min(discount, total);
    const finalTotal = total - discount;

    return { total, discount, finalTotal };
  };

  const total_price = calculateTotalPrice().total;
  const finalTotal = calculateTotalPrice().finalTotal;
  const discount_amount = calculateTotalPrice().discount;

  const handleSecureCard = (cardElement, data) => {
    enqueueSnackbar("Processing Order...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(data.client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        setIsLoadingCard(false);
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        handlepurchaseFinal(data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setIsLoadingCard(false);
      });
  };

  const handlepurchaseFinal = async (data) => {
    delete data.client_secret;
    const result = await PurchaseApiConfurm(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (!order_id) {
        setCardElement([]);
      }
      setShowPaymentPage(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let find_country = bankCurrencies.find(
    (country) =>
      country.value == inputs.country?.value &&
      country.fire_payment_enabled == true
  );

  const handlepurchase = async (cardElement) => {
    let shipping_info = {
      street: billingObject.street,
      city: billingObject.city,
      zip_code: billingObject.zip_code,
      state: billingObject.state,
      country: billingObject.country?.value,
    };

    let billing_info = {
      street: inputs.street,
      city: inputs.city,
      zip_code: inputs.zip_code,
      state: inputs.state,
      country: inputs.country?.value,
    };
    let postData = {
      save_in_profile: inputs.save_in_profile,
      shipping_charges: customDutyTax,
      is_shipping_same_as_billing: inputs.is_shipping_same_as_billing,
      currency: selectedProducts[0].currency.toLowerCase(),
      billing_object: {
        ...billing_info,
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        email: inputs.email,
        contact_number: inputs.contact_number,
      },
      shipping_object: inputs.is_shipping_same_as_billing
        ? billing_info
        : shipping_info,
    };
    if (order_id) {
      postData.order = order_id;
    }
    if (selectedTax) {
      postData.tax_rate_id = selectedTax._id;
    }
    if (inputs.coupon_info && discount_amount > 0) {
      postData.coupon_code = inputs.coupon_code;
      postData.discount_amount = discount_amount;
    }

    if (inputs.save_in_profile) {
      setUserInfo((old) => ({ ...old, ...postData.billing_object })); //updating data in profile
    }

    if (isBankPayment && find_country) {
      postData.sub_total = total_price;
      postData.total = total_price + customDutyTax;
      const result = await dynamite_product_purchase_by_bank_api(postData);
      if (result.code === 200 && result.redirect_url) {
        handle_open_browser(result.redirect_url);
        setCardElement([]);
        navigate(`/order-history`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
        if (result.code === 203) {
          navigate(`/order-history`);
        }
      }
    } else {
      const result = await PurchaseApi(postData);
      if (result.code === 200) {
        let data = {
          ...postData,
          client_secret: result.client_secret,
          order: result.order,
          payment_intent_id: result.payment_intent_id,
        };
        handleSecureCard(cardElement, data);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
        if (result.code === 203) {
          navigate(`/order-history`);
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!(isBankPayment && find_country) && elements == null) {
      return;
    }

    const parsedPhoneNumber = parsePhoneNumber(`+${inputs.contact_number}`);
    const isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
    if (!isValid) {
      enqueueSnackbar("Please enter a valid phone number", {
        variant: "error",
      });
      return;
    }
    if (!inputs.street) {
      enqueueSnackbar("Please enter valid address", { variant: "error" });
      return;
    }
    if (!inputs.country?.value) {
      enqueueSnackbar("Please enter valid country", { variant: "error" });
      return;
    }
    if (!inputs.is_shipping_same_as_billing && !billingObject.country?.value) {
      enqueueSnackbar("Please enter valid country for billing address", {
        variant: "error",
      });
      return;
    }

    let cardElement = null;
    setIsLoadingCard(true);
    if (isBankPayment && find_country) {
      handlepurchase(cardElement);
    } else {
      cardElement = elements.getElement(CardElement);
      stripe.createToken(cardElement).then(function (result) {
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        handlepurchase(cardElement);
      });
    }
  };

  const get_country = (data) => {
    return options.find((c) => c.value == data.country);
  };

  const get_order_detail = async () => {
    setIsLoading(true);
    const result = await get_shop_orders_detail(order_id);
    if (result.code === 200) {
      setOrderDetail(result.order_detail);
      let {
        order_items,
        currency,
        billing_address,
        shipping_address,
        is_shipping_same_as_billing,
      } = result.order_detail;

      if (order_items?.length == 0) {
        navigate("/shop");
      }

      let products = order_items.map((item) => {
        return {
          ...item,
          name: item.product_name,
          currency: currency,
          selected_quantity: item.quantity,
        };
      });
      setSelectedProducts(products);
      setPhoneData(billing_address.contact_number);

      setInputs({
        ...billing_address,
        country: get_country(billing_address),
        is_shipping_same_as_billing: is_shipping_same_as_billing,
      });
      let coupon_code = result.order_detail.other_info?.coupon_code;
      if (coupon_code) {
        setInputs((old) => ({ ...old, coupon_code }));
        setCheckValidity(true);
      }

      if (!is_shipping_same_as_billing) {
        setBillingObject({
          ...shipping_address,
          country: get_country(shipping_address),
        });
      }

      setIsOrderPlaced(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/order-history");
    }
  };

  useEffect(() => {
    if (order_id) {
      get_order_detail(order_id);
    } else {
      if (cartElement.length > 0) {
        setSelectedProducts(cartElement);
      }
      let find_country = options.find((c) => c.value == userInfo.country);
      if (!userInfo.country || !find_country) {
        find_country = options.find((c) => c.value == handleGetCountry());
      }
      let address_obj = {
        street: get_address(userInfo.street),
        city: get_address(userInfo.city),
        state: get_address(userInfo.state),
        zip_code: get_address(userInfo.zip_code),
        country: find_country,
      };
      setInputs({ ...inputs, ...userInfo, ...address_obj });
      setPhoneData(userInfo.contact_number);
    }
  }, [cartElement, order_id]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let grandTotal = finalTotal + +customDutyTax + +taxCharges;

  return (
    <>
      {showPaymentPage ? (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row shopping-cart-checkout">
              <div className="col-12 d-flex mb-3">
                <IconButton
                  className="back-screen-button"
                  onClick={() => {
                    if (!isLoadingCard) {
                      navigate(-1);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <h2 className="ms-2 mt-2">
                  {htmlDecode(shopContentSettings.check_out_heading)}
                </h2>
              </div>
              <div className="col-lg-7 col-md-12 ">
                <div className="card p-3 h-100">
                  <h4 className="mb-4">
                    {htmlDecode(shopContentSettings.order_detail_heading)}
                  </h4>
                  <div className="order-table table-responsive">
                    <table
                      className="main-table table table-bordered"
                      style={{ color: "white", border: "1px solid #3d3b3b" }}
                    >
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <ProductsList selectedProducts={selectedProducts} />
                      <tfoot>
                        <tr>
                          <td colSpan="3" className="text-right">
                            {htmlDecode(
                              shopContentSettings.order_detail_subtotal_text
                            )}
                          </td>
                          <td>
                            <>£{total_price.toFixed(2)}</>
                          </td>
                        </tr>
                        {discount_amount > 0 && (
                          <tr>
                            <td colSpan="3" className="text-right">
                              {htmlDecode(shopContentSettings.discount_info)}
                            </td>
                            <td>
                              <>- £{discount_amount.toFixed(2)}</>
                            </td>
                          </tr>
                        )}

                        <CustomDutyTax
                          customDutyTax={customDutyTax}
                          setCustomDutyTax={setCustomDutyTax}
                          inputs={inputs}
                          billingObject={billingObject}
                        />

                        <TaxRate
                          selectedTax={selectedTax}
                          totalPrice={total_price}
                          inputs={inputs}
                          setSelectedTax={setSelectedTax}
                          setTaxCharges={setTaxCharges}
                          taxCharges={taxCharges}
                        />
                        <tr>
                          <td
                            colSpan="3"
                            className="text-right"
                            style={{ color: "#f6bd4b" }}
                          >
                            {htmlDecode(
                              shopContentSettings.order_detail_grand_total_text
                            )}
                          </td>
                          <td style={{ color: "#f6bd4b" }}>
                            <>£{grandTotal.toFixed(2)}</>
                          </td>
                        </tr>
                        <CurrencyExchange
                          selectedFireCountry={selectedFireCountry}
                          totalAmount={totalAmount}
                          inputs={inputs}
                          setTotalAmount={setTotalAmount}
                          setInputs={setInputs}
                          billingObject={billingObject}
                          setSelectedFireCountry={setSelectedFireCountry}
                          grandTotal={grandTotal}
                          isBankPayment={isBankPayment}
                        />
                      </tfoot>
                    </table>
                  </div>
                  {shopContentSettings.allow_discount_code && (
                    <ApplyCoupon
                      inputs={inputs}
                      setInputs={setInputs}
                      mode={shopContentSettings.payment_mode}
                      checkValidity={checkValidity}
                      setCheckValidity={setCheckValidity}
                    />
                  )}
                  {(!isOrderPlaced ||
                    orderDetail.order_status === "initiated") && (
                    <div className="row mt-4 mb-4">
                      {find_country && (
                        <div className="col-12">
                          <h2 className="text-golden">
                            {htmlDecode(
                              shopContentSettings.payment_method_heading
                            )}
                          </h2>
                          <div className="col-12 mt-4 payment-method">
                            <div
                              className="d-flex cursor-pointer"
                              onClick={() => {
                                setIsBankPayment(true);
                              }}
                            >
                              <div className="custome-radio-box me-2">
                                {isBankPayment && (
                                  <div className="selected"></div>
                                )}
                              </div>
                              <div className="custome-radio-label">
                                {htmlDecode(shopContentSettings.bank_text)}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-2 payment-method">
                            <div
                              className="d-flex cursor-pointer"
                              onClick={() => {
                                setIsBankPayment(false);
                              }}
                            >
                              <div className="custome-radio-box me-2">
                                {!isBankPayment && (
                                  <div className="selected"></div>
                                )}
                              </div>
                              <div className="custome-radio-label">
                                {htmlDecode(
                                  shopContentSettings.credit_card_text
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <>
                        {isBankPayment && find_country ? (
                          <>
                            <div className={`col-12 mt-4 text-end`}>
                              <button
                                className="small-contained-button"
                                type="submit"
                                disabled={isLoadingCard}
                              >
                                {isLoadingCard
                                  ? "Processing..."
                                  : htmlDecode(
                                      shopContentSettings.pay_now_button_text
                                    )}
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            className={`col-12 mt-4 ${
                              find_country && isBankPayment ? "col-md-7" : ""
                            }`}
                          >
                            <div className="row">
                              <h2 className="mb-3 text-golden">
                                {htmlDecode(
                                  shopContentSettings.enter_card_details_heading
                                )}
                              </h2>
                              <div className="col-12 text-end">
                                <div className="card p-2 border-color">
                                  <CardElement
                                    options={{
                                      hidePostalCode: true,
                                      style: {
                                        base: {
                                          iconColor: get_root_value(
                                            "--portal-theme-primary"
                                          ),
                                          color: get_root_value(
                                            "--text-primary-color"
                                          ),
                                          "::placeholder": {
                                            color: get_root_value(
                                              "--text-primary-color"
                                            ),
                                          },
                                          fontSize: "18px",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                                {isLoadingCard ? (
                                  <button
                                    className="small-contained-button mt-4 mb-4"
                                    disabled={true}
                                  >
                                    Processing...
                                  </button>
                                ) : (
                                  <Tooltip
                                    title={
                                      selectedProducts.length > 0
                                        ? ""
                                        : "No item in your Cart"
                                    }
                                  >
                                    <button
                                      className={`small-contained-button mt-4 mb-4 ${
                                        selectedProducts.length > 0
                                          ? ""
                                          : "disable-pointer"
                                      }`}
                                      type="submit"
                                      disabled={
                                        !stripe ||
                                        !elements ||
                                        selectedProducts.length == 0
                                      }
                                    >
                                      {htmlDecode(
                                        shopContentSettings.pay_now_button_text
                                      )}
                                    </button>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
              <UserInformation
                inputs={inputs}
                setInputs={setInputs}
                isOrderPlaced={isOrderPlaced}
                phoneData={phoneData}
                billingObject={billingObject}
                setBillingObject={setBillingObject}
              />
            </div>
          </div>
        </form>
      ) : (
        <ThankYou />
      )}
    </>
  );
};

export default ShopCheckOut;
