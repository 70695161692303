import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useState } from "react";

const CLIENT_ID =
  "411971086161-56qtue6hc5g89b33d38kmpvmpilqmpdc.apps.googleusercontent.com";
const CLIENT_SECRET = "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";
const YOUR_API_KEY = "AIzaSyAsGc06B9WNVmqNTO033bQRM8yYH_GINsg";
const SCOPE =
  "https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl ";

const YOUR_REDIRECT_URI = "https://188b-182-185-217-201.ngrok-free.app";

export default function LiveBroadcasts() {
  const [accessToken, setAccessToken] = useState("");

  const createBroadcast = async () => {
    try {
      const reqObj = {
        method: "POST",
        url: `https://youtube.googleapis.com/youtube/v3/liveStreams?part=snippet,status&key=${YOUR_API_KEY}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          snippet: {
            title: "My Live Stream",
          },
          cdn: {
            frameRate: "30fps",
            ingestionType: "rtmp",
            resolution: "1080p",
          },
        },
      };
      console.log(reqObj, "reqObjreqObjreqObjreqObj");

      const results = await axios(reqObj);
      console.log(results.data);
    } catch (error) {
      console.error("Error creating broadcast:", error);
    }
  };

  return (
    <div>
      <h1>YouTube Live Streaming Integration</h1>
      {accessToken ? (
        <div>
          <button onClick={createBroadcast}>Create Broadcast</button>
          {/* Other live streaming controls */}
        </div>
      ) : (
        <div>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleLogin setAccessToken={setAccessToken} />
          </GoogleOAuthProvider>
        </div>
      )}
    </div>
  );
}

function GoogleLogin({ setAccessToken }) {
  const [error, setError] = useState(null);
  const responseGoogle = async (response) => {
    console.log(response, "responseresponseresponse");
    if (response.error === "popup_closed_by_user") {
      setError("Authentication popup closed by the user. Please try again.");
      return;
    }

    try {
      const tokenResponse = await axios.post(
        "https://oauth2.googleapis.com/token",
        {
          code: response.code,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET, // Add your client secret here
          redirect_uri: YOUR_REDIRECT_URI,
          grant_type: "authorization_code",
        }
      );

      console.log(tokenResponse, "tokenResponsetokenResponse");

      // Set the access token
      setAccessToken(tokenResponse.data.access_token);
    } catch (error) {
      console.error(
        "Error exchanging authorization code for access token:",
        error
      );
      setError("Error exchanging authorization code for access token.");
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => responseGoogle(codeResponse),
    flow: "auth-code",
    scope: SCOPE,
  });

  return (
    <div>
      <button onClick={login}>click</button>
      {error && <p>{error}</p>}
    </div>
  );
}
