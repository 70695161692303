import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
//
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  delete_member_notification,
  notification_list_with_pagination,
  read_all_notification,
  read_notification_by_id,
} from "src/DAL/Notification/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { projectMode, s3baseUrl } from "src/config/config";
import ChatPopover from "./ChatPopover";
import CartPopover from "src/pages/DDShop/CartPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { settings_routes } from "src/utils/constants";
import SettingsIcon from "@mui/icons-material/Settings";
import { mobile_banner } from "src/assets";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 42;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.1),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP + 20,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  onOpenSetting: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar, onOpenSetting }) {
  const {
    socket,
    notificationsCount,
    userInfo,
    setNotificationSettings,
    navbarList,
  } = useContentSetting();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/feeds/notification_list_with_pagination?page=0&limit=10"
  );

  const getMoreNotificationList = async (notifications, load_path) => {
    const result = await notification_list_with_pagination(load_path);
    if (result.code === 200) {
      var newArray = notifications.concat(result.notification_array);
      setNotifications(newArray);
      setPageNumber((page) => page + 1);
      setTotalPages(result.total_pages);
      setUnreadNotificationCount(result.unread_notification_count);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoadingMore(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleRedirect = async (
    notification_id,
    feed_id,
    notification_type,
    support_ticket,
    detailed_description,
    data
  ) => {
    console.log(data, "handleRedirect");
    setOpen(false);
    const result = await read_notification_by_id(notification_id);
    if (data.is_seen == false) {
      setUnreadNotificationCount((old) => old - 1);
    }
    if (result.code === 200) {
      setNotifications((notifications) => {
        const notification_list = notifications.map((notification) => {
          if (notification._id === notification_id) {
            return {
              ...notification,
              is_seen: true,
            };
          } else {
            return notification;
          }
        });
        return notification_list;
      });
      if (notification_type === "goal_statement") {
        navigate(`/goal-statement`);
      } else if (notification_type === "daily_dynamite_reminder") {
        navigate(`/dynamite-diary`);
      } else if (notification_type === "daily_dynamite_intention_reminder") {
        navigate(`/dynamite-diary`);
      } else if (notification_type === "meditation_reminder") {
        navigate(`/mediation`);
      } else if (notification_type === "gratitude_reminder") {
        navigate(`/gratitude-daily`);
      } else if (notification_type === "support_ticket_comment") {
        navigate(`/support-ticket/${support_ticket}`);
      } else if (notification_type === "send_support_ticket_reminder") {
        navigate(`/support-ticket`);
      } else if (notification_type === "close_support_ticket") {
        navigate(`/support-ticket/${support_ticket}`);
      } else if (notification_type === "event_reminder") {
        navigate(`/calendar-events`);
      } else if (notification_type === "message") {
        navigate(`/chat?chat_id=${data.message.chat_id}`);
      } else if (notification_type === "pending_payment_request_reminder") {
        navigate(`/pending-request/${data.module_id}`);
      } else if (notification_type === "goal_statement_incomplete") {
        navigate(`/goal-statement`);
      } else if (notification_type === "appointment_booking") {
        navigate(`/bookings`);
      } else if (notification_type === "self_image_responded") {
        navigate(
          `/self-image/previous-histories/${data.module_info?.module_id}`
        );
      } else if (notification_type === "self_image") {
        navigate(`/self-image`);
      } else if (
        notification_type === "payment_reminder_after_expiry" ||
        notification_type === "payment_reminder_before_expiry" ||
        notification_type === "payment_succeded" ||
        notification_type === "subscription_cancelled" ||
        notification_type === "payment_failed" ||
        notification_type === "payment_reminder"
      ) {
        navigate(`/email-template`, { state: detailed_description });
      } else if (
        data.feeds?.feed_created_for == "event" &&
        data.feeds?.dynamite_event
      ) {
        navigate(
          `/live-events/${data.feeds?.dynamite_event}/feed-detail/${feed_id}`
        );
      } else {
        navigate(`/feed-detail/${feed_id}`);
      }
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMarkAllAsRead = () => {
    setUnreadNotificationCount(0);
    read_all_notification();
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        is_seen: true,
      }))
    );
  };

  useEffect(() => {
    setPageNumber(0);
    setTotalPages(0);
    if (open) {
      getMoreNotificationList([], loadMore);
    }
  }, [open]);

  const get_notification_variable = (_data) => {
    let notification_variable = "";
    if (
      _data.notification_type === "commentlike" ||
      _data.notification_type === "feedlike" ||
      _data.notification_type === "gratitude" ||
      _data.notification_type === "addcomment" ||
      _data.notification_type === "addcommentreply"
    ) {
      notification_variable = "feed_notifications";
    } else if (_data.notification_type === "daily_dynamite_reminder") {
      notification_variable = "daily_dynamite_notification";
    } else if (_data.notification_type === "meditation_reminder") {
      notification_variable = "meditation_notification";
    } else if (_data.notification_type === "gratitude_reminder") {
      notification_variable = "gratitude_notification";
    } else if (_data.notification_type === "event_reminder") {
      notification_variable = "calendar_notification";
    } else if (_data.notification_type === "goal_statement_incomplete") {
      notification_variable = "goal_statement_notification";
    } else if (_data.notification_type === "goal_statement") {
      notification_variable = "goal_statement_notification";
    } else if (
      _data.notification_type === "support_ticket_comment" ||
      _data.notification_type === "send_support_ticket_reminder" ||
      _data.notification_type === "close_support_ticket"
    ) {
      notification_variable = "support_ticket_notification";
    }
    return notification_variable;
  };

  const showNotification = (_data) => {
    console.log(_data, "_data_data_data_data_data");
    const notification = new Notification("New Notification", {
      body: _data.body,
      icon: _data.icon,
      tag: _data.notification_id,
    });
    notification.onclick = async function (event) {
      event.preventDefault();
      handleRedirect(
        _data.notification_id,
        _data.feed_id,
        _data.notification_type,
        _data.support_ticket_id,
        _data.detail_description,
        _data
      );
    };
  };

  const getNotificationPermission = (dataObject) => {
    console.log(dataObject, "dataObjectdataObjectdataObject");
    if (Notification.permission === "granted") {
      showNotification(dataObject);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(dataObject);
        }
      });
    }
  };

  let checkNotificationPermisson = (dataObject) => {
    const notification_variable = get_notification_variable(dataObject);
    var data = {};
    setNotificationSettings((status) => {
      data = status;
      return status;
    });
    if (notification_variable) {
      if (data[notification_variable]) {
        getNotificationPermission(dataObject);
      }
    } else {
      getNotificationPermission(dataObject);
    }
  };

  const showSocketNotification = (_data) => {
    const dataObject = {
      action: _data.action,
      body: _data.action_response.notification?.notification_title,
      icon:
        s3baseUrl +
        _data.action_response.notification.user_info_sender?.profile_image,
      feed_id: _data.feed_id,
      notification_id: _data.action_response.notification._id,
      notification_type: _data.action_response.notification.notification_type,
      support_ticket_id: _data?.action_response?.notification?.support_ticket,
      ..._data.action_response.notification,
    };
    checkNotificationPermisson(dataObject);
  };

  const showRequestNotification = (_data) => {
    const dataObject = {
      action: _data.notification_type,
      body: _data.message,
      icon: s3baseUrl + _data.user_info_sender?.profile_image,
      feed_id: "",
      notification_id: _data._id,
      notification_type: _data.notification_type,
      module_id: _data.module_id,
    };
    checkNotificationPermisson(dataObject);
  };

  const showPaymentNotification = (_data) => {
    const dataObject = {
      action: _data.action,
      body: _data.action_response.notification?.notification_title,
      icon:
        s3baseUrl +
        _data.action_response.notification.user_info_sender?.profile_image,
      feed_id: _data.feed_id,
      notification_id: _data.action_response.notification._id,
      notification_type: _data.action_response.action,
      support_ticket_id: _data?.action_response?.notification?.support_ticket,
      detail_description:
        _data?.action_response?.notification?.detailed_description,
    };
    checkNotificationPermisson(dataObject);
  };
  const showGoalStatementNotification = (_data) => {
    const dataObject = {
      action: _data.action,
      body: _data.action_response.notification?.notification_title,
      icon:
        s3baseUrl +
        _data.action_response.notification.user_info_sender?.profile_image,
      // feed_id: _data.feed_id,
      notification_id: _data.action_response.notification._id,
      notification_type: _data.action_response.action,
      support_ticket_id:
        _data?.action_response?.notification?.notification_type,
      // detail_description:
      //   _data?.action_response?.notification?.detailed_description,
    };
    checkNotificationPermisson(dataObject);
  };

  const showSocketNotificationSupport = (_data, notification) => {
    let image;
    if (_data.action_response.notification.sender?.profile_image) {
      image =
        s3baseUrl + _data.action_response.notification.sender?.profile_image;
    } else if (
      _data.action_response.notification.user_info_sender.profile_image
    ) {
      image =
        s3baseUrl +
        _data.action_response.notification.user_info_sender.profile_image;
    } else {
      image = imagePlaceHolder;
    }
    const dataObject = {
      action: _data.action,
      body: _data.action_response.notification.notification_title,
      icon: image,
      feed_id: _data.feed_id,
      notification_id: notification._id,
      notification_type: _data.action ? _data.action : "support_ticket",
      support_ticket_id: _data?.action_response?.notification?.support_ticket,
    };
    checkNotificationPermisson(dataObject);
  };

  useEffect(() => {
    socket.on("new_notification_receiver", (data) => {
      console.log(data, "new_notification_receiver");
      setUnreadNotificationCount(
        data.action_response.unread_notification_count
      );
      if (
        data.action === "feedunlike" ||
        data.action === "commentunlike" ||
        data.action === "delete_comment" ||
        data.action === "poll_answer_removed" ||
        data.action === "survey_answer_removed" ||
        data.action === "delete_comment_reply"
      ) {
        setNotifications((notifications) => {
          return notifications.filter(
            (notification) =>
              notification._id !== data.action_response.notification
          );
        });
      } else if (
        data.action === "commentlike" ||
        data.action === "feedlike" ||
        data.action === "gratitude" ||
        data.action === "add_comment" ||
        data.action === "goal_statement_save_and_close_status" ||
        data.action === "poll_answer" ||
        data.action === "survey_answer" ||
        data.action === "self_image_responded" ||
        data.action === "self_image" ||
        data.action === "add_comment_reply"
      ) {
        setNotifications((notifications) => [
          data.action_response.notification,
          ...notifications,
        ]);
        showSocketNotification(data);
      }
    });

    socket.on("reminder_event", (data) => {
      let find_user = data.notification_users.find(
        (user) => user.user_id == userInfo._id
      );
      if (find_user) {
        setUnreadNotificationCount(find_user.unread_notification_count);
        if (find_user.notification.length > 0) {
          find_user.notification.map((notification) => {
            let data_object = {
              action: notification.notification_type,
              action_response: {
                notification: {
                  ...notification,
                  notification_title: notification.message,
                },
              },
            };
            showSocketNotification(data_object);
          });
        }
      }
    });
    socket.on("reminder_event_for_client", (data) => {
      console.log(data, "reminder_event_for_client");
      data.notification_array.map((notification) => {
        let find_user = notification.notification_recievers.find(
          (user) => user.user_id == userInfo._id
        );
        if (find_user) {
          setUnreadNotificationCount((old) => old + 1);
          let data_object = {
            action: notification.notification_type,
            action_response: {
              notification: {
                ...notification,
                notification_title: notification.message,
              },
            },
          };
          showSocketNotification(data_object);
        }
      });
    });
    socket.on("mention_user_event_trigger", (data) => {
      console.log(data, "mention_user_event_trigger");
      let find_user = data.action_response.notification_users.find(
        (user) => user.user_id == userInfo._id
      );
      if (find_user) {
        setUnreadNotificationCount(find_user.unread_notification_count);
        let data_object = {
          ...find_user.notification,
          action: "feed_mentioned",
          feed_id: data.feed_id,
          feed: data.action_response.feed,
          action_response: {
            notification: {
              ...find_user.notification,
              notification_title: data.action_response.notification_statement,
            },
          },
        };
        showSocketNotification(data_object);
      }
    });
    socket.on("comment_mention_user_event_trigger", (data) => {
      console.log(data, "comment_mention_user_event_trigger");
      let find_user = data.action_response.notification_users.find(
        (user) => user.user_id == userInfo._id
      );
      if (find_user) {
        setUnreadNotificationCount(find_user.unread_notification_count);
        let data_object = {
          ...find_user.notification,
          action: "comment_mentioned",
          feed_id: data.action_response.feed._id,
          feed: data.action_response.feed,
          action_response: {
            notification: {
              ...find_user.notification,
              notification_title: data.action_response.notification_statement,
            },
          },
        };
        showSocketNotification(data_object);
      }
    });
    socket.on("new_notification_receiver_for_client", (data) => {
      let count = data.data.action_response.unread_notification_count.find(
        (value) => value._id == userInfo._id
      );
      setUnreadNotificationCount(count.unread_notification_count);
      let notification_array =
        data.data.action_response.notification_array.find(
          (value) => value.reciever == userInfo._id
        );

      if (notification_array) {
        setNotifications((notifications) => [
          data.data.action_response.notification,
          ...notifications,
        ]);
        showSocketNotificationSupport(data.data, notification_array);
      }
    });
    socket.on("payment_reminder_before_expiry", (data) => {
      setUnreadNotificationCount(
        data?.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data?.action_response.notification,
        ...notifications,
      ]);
      showPaymentNotification(data);
    });

    socket.on("pending_payment_request_reminder", (data) => {
      console.log(data, "pending_payment_request_reminder");
      setUnreadNotificationCount(data?.unread_notification_count);
      setNotifications((old) => [
        {
          ...data?.notification[0],
          notification_title: data?.notification[0].message,
        },
        ...old,
      ]);
      showRequestNotification(data?.notification[0]);
    });

    socket.on("goal_statement_incomplete_reciever", (data) => {
      console.log(data, "goal_statement_incomplete_reciever");
      setUnreadNotificationCount(data?.unread_notification_count);
      setNotifications((old) => [
        {
          ...data?.notification[0],
          notification_title: data?.notification[0].message,
        },
        ...old,
      ]);
      showRequestNotification(data?.notification[0]);
    });
    socket.on("payment_reminder_after_expiry", (data) => {
      setUnreadNotificationCount(
        data?.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data?.action_response.notification,
        ...notifications,
      ]);
      showPaymentNotification(data);
    });
    socket.on("payment_succeded", (data) => {
      setUnreadNotificationCount(
        data?.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data?.action_response.notification,
        ...notifications,
      ]);
      showPaymentNotification(data);
    });
    socket.on("subscription_cancelled", (data) => {
      setUnreadNotificationCount(
        data?.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data?.action_response.notification,
        ...notifications,
      ]);
      showPaymentNotification(data);
    });
    socket.on("payment_failed", (data) => {
      setUnreadNotificationCount(
        data?.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data?.action_response.notification,
        ...notifications,
      ]);
      showPaymentNotification(data);
    });
    socket.on("goal_stetement_event_reciever", (data) => {
      setUnreadNotificationCount(
        data.data.action_response?.unread_notification_count
      );
      setNotifications((notifications) => [
        data.data.action_response.notification,
        ...notifications,
      ]);
      showGoalStatementNotification(data.data);
    });

    return () => {
      // socket.off("live_feed_room_reciever");
    };
  }, []);

  useEffect(() => {
    setUnreadNotificationCount(notificationsCount);
  }, [notificationsCount]);

  const MarkAsRead = (row) => {
    read_notification_by_id(row._id);
    setNotifications((old) => {
      return old.map((item) => {
        if (item._id == row._id) {
          return {
            ...item,
            is_seen: true,
          };
        }
        return item;
      });
    });
    setUnreadNotificationCount((old) => old - 1);
  };
  const handleAgreeDelete = (row) => {
    delete_member_notification(row._id);
    setNotifications((old) => {
      return old.filter((item) => item._id !== row._id);
    });
    if (!row.is_seen) {
      setUnreadNotificationCount((old) => old - 1);
    }
  };

  const handleAllDelete = () => {
    setOpenDelete(false);
    delete_member_notification("all");
    setNotifications([]);
    setUnreadNotificationCount(0);
    setTotalPages(0);
    setPageNumber(0);
  };

  const handleOpenAllDelete = () => {
    setOpenDelete(true);
  };

  const handle_menu_options = (row) => {
    let MENU_OPTIONS = [
      {
        label: "Remove this notification",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    if (!row.is_seen) {
      MENU_OPTIONS.push({
        label: "Mark as read",
        icon: "solar:check-read-linear",
        handleClick: MarkAsRead,
      });
    }
    return MENU_OPTIONS;
  };

  let isMatchRoute = settings_routes.some((route) => pathname.includes(route));
  let is_shop = navbarList.find((nav) => nav.value === "shop");

  return (
    <>
      <RootStyle>
        <ToolbarStyle>
          <IconButton
            className="menu-icon-settings"
            onClick={onOpenSidebar}
            sx={{
              mr: 1,
              color: "text.primary",
              marginRight: "auto",
              marginLeft:
                isMatchRoute && window.screen.width > 1200 ? "280px" : "",
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
          {isMatchRoute && window.screen.width < 1201 && (
            <IconButton className="menu-icon-settings" onClick={onOpenSetting}>
              <SettingsIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {is_shop && <CartPopover />}
            {/* Chat Popover */}
            <ChatPopover />

            {/* Notifications Popover */}
            <NotificationsPopover
              notifications={notifications}
              isLoadingMore={isLoadingMore}
              isLoading={isLoading}
              setIsLoadingMore={setIsLoadingMore}
              totalPages={totalPages}
              pageNumber={pageNumber}
              unreadNotificationCount={unreadNotificationCount}
              getMoreNotificationList={getMoreNotificationList}
              loadMorePath={loadMorePath}
              handleRedirect={handleRedirect}
              handleMarkAllAsRead={handleMarkAllAsRead}
              setOpen={setOpen}
              open={open}
              handle_menu_options={handle_menu_options}
              handleOpenAllDelete={handleOpenAllDelete}
            />
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove all notifications?"}
        handleAgree={handleAllDelete}
      />
    </>
  );
}
