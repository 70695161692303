import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  get_community_content,
  get_feeds_detail,
} from "src/DAL/Community/Community";

import FeedDetailPopup from "./FeedDetailPopup";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function PostDetails({ feeds_type }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const [feedDetail, setFeedDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [feedSetting, setFeedSetting] = useState({});

  const getFeedsDeteil = async () => {
    setIsLoading(true);
    const result = await get_feeds_detail(params.post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      getFeedsSetting();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/thesource`);
      setIsLoading(false);
    }
  };
  const getFeedsSetting = async () => {
    setIsLoading(true);
    const result = await get_community_content();
    if (result.code === 200) {
      setFeedSetting(result.feed_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/thesource`);
      setIsLoading(false);
    }
  };

  const handleSuccessDelete = (post) => {
    if (!params.event_id) {
      navigate(`/thesource`);
    } else if (params.event_id) {
      navigate(`/live-events/${params.event_id}`);
    } else {
      navigate(-1);
    }
  };

  const handleSuccessUpdate = (post) => {
    setIsLoading(true);
    getFeedsDeteil();
  };

  const handleCloseDetailBox = () => {
    navigate("/thesource");
  };

  const handleChangePage = () => {
    if (!params.event_id) {
      navigate(`/thesource`);
    } else if (params.event_id) {
      navigate(`/live-events/${params.event_id}`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getFeedsDeteil();
    window.scroll(0, 0);
  }, [params.post_id]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={handleChangePage}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <FeedDetailPopup
        handleCloseDetailBox={handleCloseDetailBox}
        post_id={params.post_id}
        feed={feedDetail}
        getFeedsListingOnAddFeed={getFeedsDeteil}
        handleSuccessDelete={handleSuccessDelete}
        handleSuccessUpdate={handleSuccessUpdate}
        feeds_type={feeds_type}
        event_id={params.event_id}
        feedSetting={feedSetting}
        selectedImageIndex={0}
        is_detail_page={true}
      />
    </>
  );
}

export default PostDetails;
