import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const addMeditationApi = async (data) => {
  const requestObj = {
    path: `api/dynamite_meditation/add_daily_meditation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateMeditationApi = async (id, data) => {
  const requestObj = {
    path: `api/dynamite_meditation/update_mediation/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const meditationListApi = async () => {
  const requestObj = {
    path: `api/dynamite_meditation/list_member_meditation`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const meditationListWithDate = async (data) => {
  const requestObj = {
    path: `api/dynamite_meditation/get_meditation_with_date_or_id_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteMeditationApi = async (id) => {
  const requestObj = {
    path: `api/dynamite_meditation/delete_mediation/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
