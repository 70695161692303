import React from "react";
import { Line } from "react-chartjs-2";
import { get_root_value } from "src/utils/domUtils";
import { convertCurrencyToSign } from "src/utils/constants";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import moment from "moment";
const findMaxYValue = (dataArray) => {
  return dataArray.reduce(
    (max, item) => (item.y > max ? item.y : max),
    Number.NEGATIVE_INFINITY
  );
};
const ChartJsHistory = ({
  memberEarning,
  targetToAchieve,
  totalAmount,
  startDate,
  planInfo,
}) => {
  let days = [];
  for (let index = 1; index <= 90; index++) {
    days.push(index);
  }

  let demoXYValues = [];
  let earningsByDay = {};
  startDate = new Date(startDate);

  // Group earnings by date and sum the values for the same date
  memberEarning.forEach((earning) => {
    const formattedDate = moment(earning.date).format("YYYY-MM-DD");

    // Check if earning is a string and convert it into a number
    let earningValue = earning.earning;
    if (typeof earningValue === "string") {
      earningValue = parseFloat(earningValue);
    }

    // Ensure earning is a valid number
    if (!isNaN(earningValue)) {
      if (earningsByDay[formattedDate]) {
        // If the date already exists, sum the earnings
        earningsByDay[formattedDate] += earningValue;
      } else {
        // If the date is new, set the initial earning
        earningsByDay[formattedDate] = earningValue;
      }
    }
  });

  Object.keys(earningsByDay).forEach((day) => {
    let earningDate = new Date(day);
    let targetDate = new Date(planInfo.target_date);

    console.log(earningDate, startDate, targetDate, "date");
    if (earningDate >= startDate && earningDate <= targetDate) {
      const start_date = moment(moment(startDate).format("YYYY-MM-DD"));
      const today_date = moment(day);
      const total_days = today_date.diff(start_date, "days");

      demoXYValues.push({
        x: total_days < 0 ? 0 : total_days,
        y: earningsByDay[day],
      });
    }
  });

  const labels = days;
  targetToAchieve = Number(targetToAchieve);
  totalAmount = Number(totalAmount);
  const maxAmount =
    findMaxYValue(demoXYValues) > 1000
      ? findMaxYValue(demoXYValues) + 1000
      : findMaxYValue(demoXYValues) + 500;

  const maxAmountValue = Number(maxAmount);
  const user_profile = _get_user_from_localStorage();

  const options = {
    backgroundColor: get_root_value("--sidebars-background-color"),
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          fontColor: get_root_value("--input-text-color"),
          color: get_root_value("--input-text-color"),
          borderColor: get_root_value("--input-text-color"),
        },
        maxTicksLimit: 25,
        max: 90,
        ticks: {
          color: get_root_value("--input-text-color"),
        },
      },
      y: {
        grid: {
          color: get_root_value("--input-text-color"),
        },
        min: 0,
        max: maxAmountValue === 0 ? 1000 : maxAmountValue,
        ticks: {
          stepSize: 1,
          color: get_root_value("--input-text-color"),
          maxTicksLimit: 6,
          precision: 0,
          userCallback: function (label) {
            if (Math.floor(label) === label) {
              return label;
            }
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: `${convertCurrencyToSign(
          user_profile.nineteen_day_plan_currency
        )}`,
        data: demoXYValues, // this is in the form of x:, y:
        borderColor: get_root_value("--portal-theme-primary"),
        backgroundColor: get_root_value("--portal-theme-primary"),
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };
  console.log(data, "datadatadata");
  return (
    <div id="chartContainer">
      <div className="row">
        <div className="col-md-12 text-center">
          <Line options={options} data={data} height={180} />
        </div>
      </div>
    </div>
  );
};

export default ChartJsHistory;
