import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { get_short_string } from "src/utils/constants";

const ReplaysList = ({ categories, eventDetail }) => {
  const navigate = useNavigate();
  const handleNavigate = (category_id) => {
    navigate(`/live-events/category/${eventDetail._id}/${category_id}`);
  };
  return (
    <div>
      <Card
        style={{
          height: "430px",
          color: "white",
          overflowY: "scroll",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
        className="live-comments"
      >
        {categories.length > 0 ? (
          categories.map((category, index) => (
            <div
              className={`d-flex  replay-container w-100 ${
                index === 0 ? "mt-0" : "mt-1"
              }`}
              style={{
                backgroundColor: "#1d1c1d",
                padding: "12px",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              onClick={() => handleNavigate(category._id)}
            >
              <img
                src={
                  s3baseUrl +
                  (category.image
                    ? category.image.thumbnail_1
                    : eventDetail.images.thumbnail_2)
                }
                alt="replay"
                style={{
                  height: "70px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
              <div className="replay-details ms-2">
                <h3
                  className="card-title"
                  style={{
                    fontSize: "14px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  {category.title}
                </h3>
                {category.short_description && (
                  <p style={{ fontSize: "12px", color: "#acacac" }}>
                    {get_short_string(category.short_description, 70)}
                  </p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <RecordNotFound />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ReplaysList;
