import React, { useCallback, useEffect, useRef, useState } from "react";
import UserInfoCard from "../LikesPopup/UserInfoCard";
import { s3baseUrl } from "src/config/config";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { survey_option_user_list_api } from "src/DAL/Community/SurveyFeed";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function SurveyUsers(props) {
  const { selectedFeed, selectedOption } = props;
  const classes = useStyles();
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [usersList, setUsersList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [usersLimit, setUsersLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMore, setLoadMore] = useState(
    "api/feeds/survey_option_users_list?page=0&limit=10"
  );

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, observer]
  );

  const getUsersList = async (feed) => {
    let postData = {
      feed_id: selectedFeed._id,
      option_id: selectedOption._id,
      limit: usersLimit,
      page: pageNumber,
    };

    const result = await survey_option_user_list_api(postData);
    if (result.code === 200) {
      var newArray = feed.concat(result.users);
      setUsersList(newArray);
      setPageNumber((page) => page + 1);
      setTotalPages(result.total_page);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getUsersList(usersList);
  };

  useEffect(() => {
    getUsersList([], loadMore);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row feed-activity-box">
      <div className="poll-result-heading">Option Statement</div>
      <div className="poll-statement mb-3">{selectedOption?.text}</div>
      {usersList.map((user, index) => {
        return (
          <div className="col-12 mb-2" key={index}>
            <UserInfoCard
              profile_image={
                s3baseUrl + user.user_info_action_by?.profile_image
              }
              user_name={user.user_info_action_by?.name}
              avatar_char={user.user_info_action_by?.name[0]}
              date={user?.createdAt}
            />
          </div>
        );
      })}
      {totalPages > pageNumber ? (
        <div className="col-12 text-center">
          <span
            onClick={loadMoreData}
            className="load-more-text"
            ref={lastBookElementRef}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
