import React from "react";
import { last_section_img } from "src/assets";
import CurrentPods from "./CurrentPods";
import EventVideos from "./EventVideos";
import TwitterFeeds from "./TwitterFeeds";
import AboutSection from "./AboutSection";

function LeftPart({
  showInfoBox,
  setShowInfoBox,
  upcomingEvents,
  currenEvents,
  userInfo,
  maximumLikeCount,
  bonusCount,
  descriptions,
  feedSetting,
  feeds_type,
  event_category,
  event_id,
  startingSoonPods,
  livePods,
  event_slug,
}) {
  return (
    <div className="community-left-sidebar">
      {feeds_type === "general" ? (
        <>
          <AboutSection
            setShowInfoBox={setShowInfoBox}
            showInfoBox={showInfoBox}
            userInfo={userInfo}
            maximumLikeCount={maximumLikeCount}
            bonusCount={bonusCount}
            descriptions={descriptions}
            feedSetting={feedSetting}
          />
          <CurrentPods
            feeds_type={feeds_type}
            livePods={livePods}
            startingSoonPods={startingSoonPods}
          />
          <TwitterFeeds
            upcomingEvents={upcomingEvents}
            currenEvents={currenEvents}
            userInfo={userInfo}
            feeds_type={feeds_type}
          />
          <img className="mt-3" src={last_section_img}></img>
        </>
      ) : (
        <EventVideos
          feeds_type={feeds_type}
          event_category={event_category}
          event_id={event_id}
          event_slug={event_slug}
        />
      )}
    </div>
  );
}

export default LeftPart;
