import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const visioneering_challenge_list_api = async () => {
  const requestObj = {
    path: `api/challenge/challenge_list_for_member_v1?type=visioneering`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_challange_history_by_member_api = async (data) => {
  const requestObj = {
    path: `api/member/get_challange_history_by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_challange_history_by_member_api = async (data) => {
  const requestObj = {
    path: `api/member/update_challange_history_by_member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_lifeScript_challange_history_by_member_api = async (
  data
) => {
  const requestObj = {
    path: `api/member/update_money_script_challange_history_by_member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
