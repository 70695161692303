import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { check_coupon_availability_for_web } from "src/DAL/ShopApi/ApiShop";

export default function ApplyCoupon(props) {
  const { inputs, setInputs, mode, setCheckValidity, checkValidity } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleApplyCoupon = async (e) => {
    if (e) e.preventDefault();
    if (inputs.coupon_info) {
      setInputs((old) => ({ ...old, coupon_info: null, coupon_code: "" }));
      return;
    }
    if (!inputs.coupon_code) {
      let error_message = "Please enter a coupon code";
      enqueueSnackbar(error_message, { variant: "error" });
      return;
    }
    setIsLoading(true);
    let postData = { coupon_code: inputs.coupon_code };
    const result = await check_coupon_availability_for_web(postData);
    setCheckValidity(false);
    if (result.code === 200) {
      if (result.coupon_mode == mode) {
        setInputs((old) => ({ ...old, coupon_info: result.coupon }));
      } else {
        let message = `Your request is in ${mode} mode, but you are using a ${result.coupon_mode} coupon code.`;
        enqueueSnackbar(message, { variant: "error" });
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!checkValidity) return;
    handleApplyCoupon();
  }, [checkValidity]);

  return (
    <div className="col-12 mt-3">
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <TextField
            id="outlined-basic"
            label="Enter Coupon Code"
            variant="outlined"
            size="small"
            fullWidth
            name="coupon_code"
            value={inputs.coupon_code}
            onChange={handleChange}
            disabled={Boolean(inputs.coupon_info)}
          />
        </div>
        <div className="">
          <button
            className="small-contained-button ms-2"
            disabled={isLoading}
            onClick={handleApplyCoupon}
          >
            {inputs.coupon_info ? "Remove" : isLoading ? "Loading" : "Apply"}
          </button>
        </div>
      </div>
    </div>
  );
}
