import { Dialog } from "@mui/material";
import React from "react";

export default function WhatsappPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
  show_date_and_income,
  hide_close_button,
  font_size,
}) {
  return (
    <>
      <Dialog
        className="hide-index"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: `p-3 general-popup-model ${
            show_date_and_income ? "general-popup-model-max-width" : ""
          }`,
        }}
      >
        {!hide_close_button && (
          <div
            class="cross-icon"
            onClick={() => setOpen(false)}
            style={{ cursor: "pointer" }}
          >
            x
          </div>
        )}
        {title && (
          <div className="popup-title text-center">
            <h2 style={{ fontSize: font_size ?? "27px" }}>{title}</h2>
          </div>
        )}
        {/* <hr /> */}
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
