import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const get_dashboard_setting = async () => {
  const requestObj = {
    path: `api/member/client_dashboard/v1`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_stats_data = async (type) => {
  const requestObj = {
    path: `api/member/member_stats_and_source/links?data_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_sales_listing = async () => {
  const requestObj = {
    path: `api/member/sales/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_order_listing = async () => {
  const requestObj = {
    path: `admin_users/get_shop_orders_for_client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_cards_listing = async () => {
  const requestObj = {
    path: `api/card/list/client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _pick_card_of_the_day = async (id) => {
  const requestObj = {
    path: `api/member/pick_card_of_the_day/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_mc_page_setting_api = async (slug) => {
  const requestObj = {
    path: `api/sale_page/get_page_mission_control_settings/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
