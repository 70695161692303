import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
//

import { login_as_customer } from "../DAL/Login/Login";

// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { get_app_headers } from "../config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import { encryptToken } from "src/utils/constants";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const headers = get_app_headers();
  const { handleUserInfo, handleConsultantInfo, START_SOCKET_FORCEFULLY } =
    useContentSetting();

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    const result = await login_as_customer(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem("token", encryptToken(result.token));

      if (result.member.consultant) {
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(result.member.consultant)
        );
        handleConsultantInfo(result.member.consultant);
      } else {
        localStorage.setItem(`consultant_data`, JSON.stringify({}));
      }

      navigate("/mission-control", { replace: true });
      START_SOCKET_FORCEFULLY(result.member._id);
      handleUserInfo(result.member);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {/* {project_name} Client Portal */}
              {headers.title} Portal
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
