import { websiteBaseUrl } from "src/config/config";
import { _dynamite_event_category_video_list_chat } from "src/DAL/Inception/Inception";
import {
  delete_image_on_s3_for_chat,
  get_member_event_detail_by_slug,
  update_image_on_s3_for_chat,
} from "src/DAL/LiveEvents/LiveEvents";
import { token_generate_for_upgrade_portal_api } from "src/DAL/WheelOfLife/WheelOfLifeApi";
import {
  handle_change_page,
  handle_open_browser,
  is_safari,
} from "src/utils/constants";

export const get_formatted_message = (chat, userInfo) => {
  return {
    ...chat,
    _id: chat._id,
    comment_date_time: chat.action_date_time,
    feeds: "",
    message: chat.message,
    user_info_action_for: {
      action_id: chat.member._id,
      action_by: "member_user",
      name: chat.member.first_name + " " + chat.member.last_name,
      profile_image: chat.member.profile_image,
    },
    replies: chat.replies ? chat.replies : [],
    updatedAt: chat.updatedAt,
    like_count: chat.like_count,
    createdAt: chat.createdAt,
    comment_is_self: chat.member._id === userInfo._id ? true : false,
  };
};

export const UploadImages = async (formData) => {
  const result = await update_image_on_s3_for_chat(formData);
  return result.image_path;
};

export const DeleteImages = async (image_path) => {
  const result = await delete_image_on_s3_for_chat(image_path);
};

export const handle_send_message = async (
  image,
  eventDetail,
  chatMessage,
  userInfo,
  isReply,
  parentMessage,
  socket
) => {
  let file_url = "";
  if (image) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", 600);
    formData.append("height", 700);
    file_url = await UploadImages(formData);
  }

  const event_id = eventDetail._id;
  const video_id = eventDetail.feature_video._id;
  const message = chatMessage;
  const member = {
    _id: userInfo._id,
    first_name: userInfo.first_name,
    last_name: userInfo.last_name,
    profile_image: userInfo.profile_image,
  };

  let socket_object = {
    event_id,
    video_id,
    message,
    file_url,
    member,
  };

  if (isReply) {
    socket_object.parent_message = parentMessage._id;
  }

  socket.emit("live_event_message", socket_object);
};

export const handle_update_message = async (
  eventDetail,
  oldImage,
  previews,
  image,
  chatMessage,
  socket,
  selectedComment
) => {
  let file_url = oldImage;
  if (image) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", 600);
    formData.append("height", 700);
    file_url = await UploadImages(formData);
  } else if (oldImage !== "" && previews == "") {
    const formData = new FormData();
    formData.append("image", oldImage);
    result_delete = await DeleteImages(formData);
    file_url = "";
  }

  const event_id = eventDetail._id;
  const message = chatMessage;

  socket.emit("live_event_message_update", {
    event_id,
    comment_id: selectedComment._id,
    message: message,
    file_url: file_url,
  });
};

export const handle_delete_message = async (
  eventDetail,
  selectedComment,
  socket
) => {
  const event_id = eventDetail._id;
  if (selectedComment.file_url) {
    const formData = new FormData();
    formData.append("image", selectedComment.file_url);
    const result_delete = await DeleteImages(formData);
  }
  socket.emit("live_event_message_delete", {
    comment_id: selectedComment._id,
    event_id,
  });
};

export const bottom_in = {
  hidden: {
    y: "100px",
    x: "-50%",
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: "0",
    transition: { delay: 0, duration: 1 },
  },
};

export const scroll_to_bottom_on_message = (
  live_message,
  userInfo,
  setNewChat
) => {
  let scroll = false;
  const chat_box = document.getElementsByClassName("live-comments-list")[0];
  const scroll_top = chat_box.scrollTop;
  const scroll_height = chat_box.scrollHeight;
  const offset_height = chat_box.offsetHeight;
  const available_scroll = scroll_height - offset_height - 100;

  if (live_message.user_info_action_for.action_id === userInfo._id) {
    scroll = true;
  }

  if (scroll_top > available_scroll) {
    scroll = true;
  } else {
    if (!scroll) {
      setNewChat(true);
    }
  }

  if (scroll) {
    chat_box.scrollBy({ top: scroll_height, behavior: "smooth" });
  }
};

export const scroll_to_bottom = (setNewChat, setShowScrollArrow) => {
  let scroll = true;
  const chat_box = document.getElementsByClassName("live-comments-list")[0];

  if (scroll) {
    chat_box.scrollBy({
      top: chat_box.scrollHeight + 100,
      behavior: "smooth",
    });
    setNewChat(false);
    setShowScrollArrow(false);
  }
};

export const get_bottom_padding = () => {
  if (document.getElementById("bottom-add-comment-id")) {
    const height = document.getElementById(
      "bottom-add-comment-id"
    ).offsetHeight;
    return height + 10;
  }
};

export const get_video_height = () => {
  if (document.getElementById("video-col")) {
    const height = document.getElementById("video-col").offsetHeight;
    console.log(height, "height");
    if (height > 500) {
      return height;
    } else {
      return 500;
    }
  } else {
    return 500;
  }
};

export const handleScrollListener = (setNewChat, setShowScrollArrow) => {
  var scrollTimeout;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(function () {
    const chat_box = document.getElementsByClassName("live-comments-list")[0];
    const scroll_top = chat_box.scrollTop;
    const scroll_height = chat_box.scrollHeight;
    const offset_height = chat_box.offsetHeight;
    const available_scroll = scroll_height - offset_height - 100;
    if (scroll_top > available_scroll) {
      setNewChat(false);
      setShowScrollArrow(false);
    } else {
      setShowScrollArrow(true);
    }
  }, 100);
};

export const get_pinned_comments_height = () => {
  if (document.getElementById("pinned-comment")) {
    const height = document.getElementById("pinned-comment").offsetHeight;
    return height;
  }
};

export const get_pinned_image_height = () => {
  if (document.getElementById("pinned-image")) {
    const height = document.getElementById("pinned-image").offsetHeight;
    return height;
  }
};

export const receive_new_live_message_by_socket = (
  data,
  eventDetail,
  userInfo,
  setLiveChat,
  setNewChat
) => {
  if (data.video_id === eventDetail.feature_video._id) {
    const chat_formatted_obj = get_formatted_message(data.comment_id, userInfo);
    if (data.parent_message) {
      setLiveChat((prev) => {
        return prev.map((message) => {
          if (message._id === chat_formatted_obj.parent_message) {
            return {
              ...message,
              replies: message.replies
                ? [...message.replies, chat_formatted_obj]
                : [chat_formatted_obj],
            };
          } else {
            return message;
          }
        });
      });
    } else {
      setLiveChat((prev) => [...prev, chat_formatted_obj]);
    }
    setTimeout(() => {
      scroll_to_bottom_on_message(chat_formatted_obj, userInfo, setNewChat);
    }, 500);
  }
};

export const receive_update_live_message_by_socket = (
  data,
  userInfo,
  setLiveChat,
  setPinnedComments
) => {
  const chat_formatted_obj = get_formatted_message(data.comment_id, userInfo);
  setLiveChat((liveChat) =>
    liveChat.map((chat) => {
      if (chat._id === chat_formatted_obj._id) {
        const chat_replies = chat.replies;
        chat_formatted_obj.replies = chat_replies;
        return chat_formatted_obj;
      } else if (chat._id === chat_formatted_obj.parent_message) {
        return {
          ...chat,
          replies: chat.replies.map((message) => {
            if (message._id === chat_formatted_obj._id) {
              return chat_formatted_obj;
            } else {
              return message;
            }
          }),
        };
      } else {
        return chat;
      }
    })
  );

  setPinnedComments((liveChat) =>
    liveChat.map((chat) => {
      if (chat._id === chat_formatted_obj._id) {
        return chat_formatted_obj;
      } else {
        return chat;
      }
    })
  );
};

export const receive_delete_live_message_by_socket = (
  data,
  setLiveChat,
  setPinnedComments
) => {
  setLiveChat((liveChat) =>
    liveChat.filter((chat) => chat._id !== data.comment_id)
  );
  setLiveChat((comments) => {
    const comments_list = comments.map((comment) => {
      let set_comment = {};
      const array_filtered = comment.replies.filter(
        (child_comment) => child_comment._id !== data.comment_id
      );
      set_comment = {
        ...comment,
        replies: array_filtered,
      };
      return set_comment;
    });
    return comments_list;
  });

  setPinnedComments((liveChat) =>
    liveChat.filter((chat) => chat._id !== data.comment_id)
  );
};

export const handleFeatureStatus = (comment, eventDetail, socket) => {
  socket.emit("make_message_featured_unfeatured", {
    comment_id: comment._id,
    event_id: eventDetail._id,
    video_id: eventDetail.feature_video?._id,
    is_featured: !comment.is_featured,
  });
};

export const get_live_event_chat = async (
  video_id,
  bottom_scroll = true,
  setLiveChat,
  setPinnedComments,
  setNewChat,
  setShowScrollArrow,
  userInfo,
  setIsLoading
) => {
  const result = await _dynamite_event_category_video_list_chat(video_id);
  if (result.code === 200) {
    const chats = result.dynamite_event_category_video_chat.map((chat) => {
      return get_formatted_message(chat, userInfo);
    });

    const pinned = result.dynamite_event_category_video_featured_chat.map(
      (chat) => {
        return get_formatted_message(chat, userInfo);
      }
    );
    setLiveChat(chats);
    setTimeout(() => {
      setPinnedComments(pinned);
    }, 200);

    if (bottom_scroll) {
      setTimeout(() => {
        scroll_to_bottom(setNewChat, setShowScrollArrow);
      }, 200);
    }
  } else {
  }

  setIsLoading(false);
};

export const get_live_event_detail = async (
  event_slug,
  setIsEventAvailable,
  category_slug,
  setValue,
  setEventDetail,
  setIsMemberFree,
  setCurrentEventsArray,
  setUpcomingEventsArray,
  setShowChat,
  setIsLoading,
  setLiveChat,
  setPinnedComments,
  setNewChat,
  setShowScrollArrow,
  userInfo
) => {
  const result = await get_member_event_detail_by_slug(event_slug);
  if (result.code === 200) {
    setIsEventAvailable(true);
    console.log(category_slug, "category_slugcategory_slugcategory_slug");
    if (category_slug) {
      if (screen.width < 571) {
        if (category_slug === "videos") {
          setValue(1);
        } else if (category_slug === "events") {
          setValue(2);
        } else {
          result.member_dynamite_event.dynamite_event_category.map(
            (category, index) => {
              if (category_slug === category.dynamite_event_category_slug) {
                setValue(index + 3);
              }
            }
          );
        }
      } else {
        result.member_dynamite_event.dynamite_event_category.map(
          (category, index) => {
            if (category_slug === category.dynamite_event_category_slug) {
              setValue(index + 1);
            }
          }
        );
      }
    }
    setEventDetail(result.member_dynamite_event);
    setIsMemberFree(result.is_member_free);
    setCurrentEventsArray(result.current_events_array);
    setUpcomingEventsArray(result.upcoming_events_array);
    setIsLoading(false);
    if (
      result.member_dynamite_event.feature_video?.is_feature &&
      result.member_dynamite_event.feature_video?.is_chat_enable
    ) {
      setShowChat(true);
      get_live_event_chat(
        result.member_dynamite_event.feature_video._id,
        true,
        setLiveChat,
        setPinnedComments,
        setNewChat,
        setShowScrollArrow,
        userInfo,
        setIsLoading
      );
    } else {
      setShowChat(false);
      setIsLoading(false);
    }
  } else {
    setIsEventAvailable(false);
    setIsLoading(false);
  }
};

export const receive_feature_status_by_socket = (
  data,
  eventDetail,
  setEventDetail,
  setLiveChat,
  setPinnedComments,
  setNewChat,
  setShowScrollArrow,
  userInfo,
  setIsLoading
) => {
  setEventDetail((prev) => {
    return {
      ...prev,
      is_purchase_link: data.comment_id.is_purchase_link,
      link_image: data.comment_id.link_image,
      link: data.comment_id.link,
      feature_video: prev.feature_video
        ? {
            ...prev.feature_video,
            question_configration: data.comment_id.question_configration,
          }
        : null,
    };
  });
  get_live_event_chat(
    eventDetail.feature_video._id,
    false,
    setLiveChat,
    setPinnedComments,
    setNewChat,
    setShowScrollArrow,
    userInfo,
    setIsLoading
  );
};

export const handle_validate_redirect = (
  eventDetail,
  banner_link,
  setIsLoading,
  validate
) => {
  if (validate) {
    if (
      eventDetail.is_purchase_link == true &&
      eventDetail.link.toString() == banner_link.toString()
    ) {
      handle_change_page(eventDetail.link, setIsLoading);
    } else {
      if (banner_link !== "") {
        handle_open_browser(banner_link);
      }
    }
  } else {
    eventDetail.link !== "" &&
      handle_change_page(eventDetail.link, setIsLoading);
  }
};
