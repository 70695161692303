import React, { useState } from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayer = (props) => {
  const { playingVideo = false, handleOnPlay, setWatched } = props;

  const setVideoPlayed = () => {
    setWatched(true);
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };

  const start_playing = () => {
    handleOnPlay();
  };

  return (
    <div className="wrapper">
      <ReactPlayer
        controls
        // Disable right click
        onContextMenu={(e) => e.preventDefault()}
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        // playing={playingVideo}
        pip
        playsinline
        onStart={props.handleOnPlay ? start_playing : setVideoPlayed}
      />
    </div>
  );
};

export default ReactVideoPlayer;
