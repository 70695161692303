import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  get_event_video_detail,
  get_series_video_detail,
} from "src/DAL/Community/Community";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LiveEventVideoDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [featureVideo, setFeatureVideo] = useState({});

  const getVideoContent = async () => {
    const result = await get_event_video_detail(params.video_id);
    if (result.code === 200) {
      setFeatureVideo(result.dynamite_event_category_video);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      navigate(`/live-events`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getVideoContent();
  }, []);

  const handleAskQuestion = () => {
    if (featureVideo) {
      let question_configration = featureVideo?.question_configration;
      if (
        question_configration &&
        question_configration.show_question &&
        question_configration.button_text
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleVideoQuestions = () => {
    navigate(`/live-events/video/question-answers/${featureVideo._id}`);
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container new-community-page">
      <section className="success-wrapper pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(`/live-events/${params.event_id}`)}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className="col-lg-12">
              <h1>{htmlDecode(featureVideo.title)}</h1>
              <p>{htmlDecode(featureVideo.short_description)}</p>
            </div>
            <div className="col-lg-12 mt-2">
              {featureVideo.video_url && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: featureVideo.video_url,
                  }}
                ></div>
              )}
              {featureVideo.video_url === "" && (
                <img
                  src={s3baseUrl + featureVideo.image.thumbnail_1}
                  alt=""
                  srcSet=""
                />
              )}
              {featureVideo.audio_file_url &&
                featureVideo.video_type === "audio" && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + featureVideo.audio_file_url}
                        controls
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="col-12 text-end mt-3">
              {handleAskQuestion() && (
                <button
                  className="small-contained-button"
                  onClick={handleVideoQuestions}
                >
                  {featureVideo.question_configration?.button_text}
                </button>
              )}
            </div>
            <div className="col-lg-12 mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: featureVideo.detail_description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LiveEventVideoDetails;
