import { invokeApi } from "../../bl_libs/invokeApi";

export const manage_survey_answer_api = async (data) => {
  const requestObj = {
    path: `api/feeds/manage_survey_answers`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const feed_survey_details_api = async (feed_id) => {
  const requestObj = {
    path: `api/feeds/feed_survey_details/${feed_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const survey_option_user_list_api = async (data) => {
  const requestObj = {
    path: `api/feeds/survey_option_users_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
