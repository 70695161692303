import React from "react";
import Suggestions from "./Suggestions";
import StarIcon from "@mui/icons-material/Star";
import TwitterFeeds from "./TwitterFeeds";
import { projectMode } from "src/config/config";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import LeaderBoard from "./components/LeaderBoard";

function RightPart({
  winFeedsList,
  leaderboardData,
  userInfo,
  feeds_type,
  current_events_array,
  upcoming_events_array,
  eventDetail,
  feedSetting,
  userRoadmap,
  handleChangeUserType,
  handleFavouriteVideosPage,
  handleClickAll,
}) {
  const { roadMapLevels } = useContentSetting();

  return (
    <>
      <div className="community-left-sidebar">
        {feeds_type === "general" && (
          <>
            <div className="favourite-videos-buttton-box">
              <button
                className="form-control"
                onClick={handleFavouriteVideosPage}
              >
                <StarIcon /> FAVOURITE VIDEOS
              </button>
            </div>
            {feedSetting.show_level_filter && roadMapLevels.length > 0 && (
              <div className="thesource-filter mt-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userRoadmap}
                    label="Select Level"
                    size="small"
                    onChange={handleChangeUserType}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {roadMapLevels.map((level) => {
                      return (
                        <MenuItem value={level._id.slug}>
                          {level._id.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}

            {/* {projectMode == "DEV" && (
              <div className="mt-3">
                <LeaderBoard
                  title={feedSetting.monthly_leaderboard_heading}
                  data={leaderboardData}
                  handleClickAll={handleClickAll}
                />
              </div>
            )} */}
            {winFeedsList.length > 0 && (
              <Suggestions winFeedsList={winFeedsList} />
            )}
          </>
        )}
        {feeds_type === "event" && (
          <>
            <TwitterFeeds
              upcomingEvents={upcoming_events_array}
              currenEvents={current_events_array}
              userInfo={userInfo}
              feeds_type={feeds_type}
              eventDetail={eventDetail}
            />
          </>
        )}
        {/* <TotalPoints /> */}
      </div>
    </>
  );
}

export default RightPart;
