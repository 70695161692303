import { Icon } from "@iconify/react";
import { Card, CircularProgress, Fab, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ZoomVideo from "@zoom/videosdk";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { FeedComments } from "../Community/components";
import { LiveStreamSettings, Participants } from "./components";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
let client = null;

let stream;

function LiveStream() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userInfo, adminTimeZone, socket } = useContentSetting();
  const { topic, token } = useParams();

  const [isLoadingStream, setIsLoadingStream] = useState(true);
  const [mediaStream, setMediaStream] = useState();
  const [zmClient, setZmClient] = useState();
  const [participants, setParticipants] = useState([]);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openEndStream, setOpenEndStream] = useState(false);

  const [audioStatus, setAudioStatus] = useState(true);
  const [videoStatus, setVideoStatus] = useState(false);

  const handleOpenParticipantsDrawer = () => {
    if (zmClient) {
      const _participants = zmClient.getAllUser();
      setParticipants(_participants);
    }

    setOpenParticipants(true);
  };

  const handleCloseParticipantsDrawer = () => {
    setOpenParticipants(false);
  };

  const handleOpenSettingsDrawer = () => {
    setOpenSettings(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettings(false);
  };

  const handleEndStreamConfirmation = () => {
    setOpenEndStream(true);
  };

  const handleEndStream = () => {
    if (zmClient) {
      zmClient.leave(true);
      socket.emit("end_stream_event", { feed_id: topic });
      navigate("/thesource");
    } else {
      navigate(-1);
    }
  };

  const handleStartAudio = async () => {
    console.log("handleStartAudio");
    mediaStream.startAudio({ backgroundNoiseSuppression: true });
    mediaStream.unmuteAudio();
    setAudioStatus(true);
  };

  const handleStopAudio = () => {
    console.log("handleStopAudio");
    setAudioStatus(false);
    mediaStream.muteAudio();
  };

  const handleStartVideo = () => {
    if (!!window.chrome && !(typeof SharedArrayBuffer === "function")) {
      // if desktop Chrome or Edge (Chromium) with SharedArrayBuffer not enabled
      mediaStream.startVideo({
        videoElement: document.querySelector("#self-view-video"),
        hd: true,
      });
      mediaStream.startAudio({ backgroundNoiseSuppression: true });
    } else {
      // all other browsers and desktop Chrome or Edge (Chromium) with SharedArrayBuffer enabled
      mediaStream.startVideo(() => {
        mediaStream.renderVideo(
          document.querySelector("#self-view-canvas"),
          client.getCurrentUserInfo().userId,
          1280,
          720,
          0,
          0,
          15 // Adjust the frame rate as needed
        );
      });
    }
    setVideoStatus(true);
  };

  const handleStopVideo = () => {
    mediaStream.stopVideo();
    setVideoStatus(false);
  };

  const updateParticipants = useCallback(
    (payload) => {
      console.log(payload, "--payload");
      const _participants = zmClient.getAllUser();
      console.log(_participants, "_participants_participants");
      setParticipants(_participants);
    },
    [zmClient]
  );

  const callback = (payload) => {
    console.log(payload, "--payload");
  };

  useEffect(() => {
    if (zmClient) {
      zmClient.on("user-added", updateParticipants);
      zmClient.on("user-removed", updateParticipants);
      zmClient.on("user-updated", updateParticipants);
    }

    return () => {
      if (zmClient) {
        zmClient.off("user-added", updateParticipants);
        zmClient.off("user-removed", updateParticipants);
        zmClient.off("user-updated", updateParticipants);
      }
    };
  }, [zmClient, updateParticipants]);

  useEffect(() => {
    const client = ZoomVideo.createClient();
    client.init("en-US", `CDN`);

    client
      .join(topic, token, `${userInfo.first_name} ${userInfo.last_name}`, "")
      .then(() => {
        stream = client.getMediaStream();
        const _participants = client.getAllUser();
        stream.startAudio({ backgroundNoiseSuppression: true });

        setParticipants(_participants);
        setZmClient(client);
        setMediaStream(stream);
        setIsLoadingStream(false);
      })
      .catch((error) => {
        console.log(error, "--error stream");
        enqueueSnackbar(`${error.type}: ${error.reason}`, {
          variant: "error",
        });
      });

    // client.on("user-added", callback);
    // client.on("user-removed", callback);
    // client.on("user-updated", callback);

    return () => {
      // client.off("user-added", callback);
      // client.off("user-removed", callback);
      // client.off("user-updated", callback);
      client.leave(true);
    };
  }, []);

  if (isLoadingStream) {
    return <CircularProgress className="centered" color="primary" />;
  }

  return (
    <>
      <div className="container-fluid p-4 p-md-5">
        <div className="row ">
          <div className="col-12">
            <h2>Live Stream</h2>
          </div>
        </div>

        <div className="row">
          <Card className="col-12 col-lg-8 live-stream-wrapper">
            <div className="stream-info">
              <Icon
                className="me-1"
                fontSize={16}
                style={{ color: "red" }}
                icon="carbon:dot-mark"
              />
              <span>Live</span>
            </div>

            <div
              className="stream-users-info"
              onClick={handleOpenParticipantsDrawer}
            >
              <Icon
                className="me-1"
                fontSize={16}
                icon="ic:baseline-remove-red-eye"
              />
              <span>{participants.length}</span>
            </div>

            <div
              className="end-stream-btn"
              onClick={handleEndStreamConfirmation}
            >
              <Fab
                style={{ backgroundColor: "red", color: "white" }}
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon="material-symbols:call-end"
                />
              </Fab>
            </div>

            <div
              className="settings-stream-btn"
              onClick={handleOpenSettingsDrawer}
            >
              <Fab style={{}} size="medium" variant="extended" aria-label="add">
                <Icon
                  className="stream-control-icon"
                  icon="icon-park-solid:setting-two"
                />
              </Fab>
            </div>
            {/*  */}
            <video id="self-view-video" width="100%" height="auto"></video>
            <canvas id="self-view-canvas" width="100%" height="auto"></canvas>

            {/*  */}
            <div className="live-stream-footer">
              <div className="stream-controls-wrapper">
                <div className="stream-control">
                  {videoStatus ? (
                    <Fab
                      onClick={() => handleStopVideo()}
                      className="stream-control-button-active"
                      size="medium"
                      variant="extended"
                      aria-label="add"
                    >
                      <Icon
                        className="stream-control-icon"
                        icon="iconoir:video-camera"
                      />
                    </Fab>
                  ) : (
                    <Fab
                      onClick={() => handleStartVideo()}
                      className="stream-control-button"
                      size="medium"
                      variant="extended"
                      aria-label="add"
                    >
                      <Icon
                        className="stream-control-icon"
                        icon="iconoir:video-camera-off"
                      />
                    </Fab>
                  )}
                </div>
                <div className="stream-control">
                  {audioStatus ? (
                    <Fab
                      onClick={() => handleStopAudio()}
                      className="stream-control-button-active"
                      size="medium"
                      variant="extended"
                      aria-label="add"
                    >
                      <Icon
                        className="stream-control-icon"
                        icon="ic:outline-mic"
                      />
                    </Fab>
                  ) : (
                    <Fab
                      onClick={() => handleStartAudio()}
                      className="stream-control-button"
                      size="medium"
                      variant="extended"
                      aria-label="add"
                    >
                      <Icon
                        className="stream-control-icon"
                        icon="material-symbols:mic-off"
                      />
                    </Fab>
                  )}
                </div>
              </div>
            </div>
          </Card>
          <div className="col-12 col-lg-4">
            <div className="live-stream-comments">
              <FeedComments
                feed_id={topic}
                getFeedsListingOnAddFeed={() => {}}
                setTotalCommentCount={() => {}}
              />
            </div>
          </div>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={openParticipants}
        onOpenDrawer={handleOpenParticipantsDrawer}
        onCloseDrawer={handleCloseParticipantsDrawer}
        pageTitle="Live Streams Participants"
        componentToPassDown={<Participants participants={participants} />}
      />

      <CustomDrawer
        isOpenDrawer={openSettings}
        onOpenDrawer={handleOpenSettingsDrawer}
        onCloseDrawer={handleCloseSettingsDrawer}
        pageTitle="Live Streams Settings"
        componentToPassDown={
          <LiveStreamSettings zmClient={zmClient} mediaStream={mediaStream} />
        }
      />

      <CustomConfirmation
        open={openEndStream}
        setOpen={setOpenEndStream}
        title={"Are you sure you want to end this live stream?"}
        handleAgree={handleEndStream}
      />
    </>
  );
}

export default LiveStream;
