import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { update_stripe_card } from "src/DAL/Payment/Payment";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { IconButton, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stripe from "stripe";
import { useNavigate } from "react-router-dom";

const HandleChangeCard = ({ updateCard }) => {
  const { stripeKey } = useContentSetting();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    cvc: "",
    expiry: "",
    expiry_year: "",
    focus: "",
    name: "",
    number: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleCardAction = async (card_token) => {
    let postData = { source: card_token };
    const result = await update_stripe_card(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      if (updateCard) {
        updateCard(result.stripe_customer_card);
      } else {
        navigate(`/manage-card`);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      let exp_month = Math.trunc(inputs.expiry);
      let exp_year = Math.trunc(inputs.expiry_year);

      const stripe = new Stripe(stripeKey);
      const token = await stripe.tokens.create({
        card: {
          number: inputs.number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: inputs.cvc,
          name: inputs.name,
        },
      });
      handleCardAction(token.id);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  console.log(updateCard, "updateCardwwwwwwww");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    const { name } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="d-flex">
          <span>
            <IconButton
              className="back-screen-button back-buuton-profile pt-2"
              onClick={() => navigate(`/manage-card`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">Enter Card Details</h2>
        </div>
        <div className="col-12 mb-4"></div>
        <div className="col-md-4 how-img mb-4 update-stripe-card">
          <Cards
            cvc={inputs?.cvc}
            expiry={inputs?.expiry + inputs?.expiry_year}
            focused={inputs?.focus}
            name={inputs?.name}
            number={inputs?.number}
          />
        </div>
        <div className="col-md-8">
          <form
            onSubmit={handleSubmit}
            className="text-center mt-1 mx-auto w-100"
          >
            <div className="row">
              <div className="col-lg-6 cl-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="number"
                  value={inputs?.number}
                  label="Card Number"
                  variant="outlined"
                  placeholder="Card Number"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
              </div>
              <div className="col-lg-6 cl-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="name"
                  value={inputs?.name}
                  label="Card Holder Name"
                  variant="outlined"
                  placeholder="Card Holder Name"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-lg-3 cl-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="expiry"
                  value={inputs?.expiry}
                  label="Expiry Month"
                  variant="outlined"
                  placeholder="Expiry Month"
                  fullWidth
                  inputProps={{
                    maxLength: 2,
                  }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>{" "}
              <div className="col-lg-3 cl-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="expiry_year"
                  value={inputs?.expiry_year}
                  label="Expiry Year"
                  variant="outlined"
                  placeholder="Expiry Year"
                  fullWidth
                  inputProps={{
                    maxLength: 2,
                  }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-lg-6 cl-md-6 col-sm-12 mb-3">
                <TextField
                  id="outlined-basic"
                  name="cvc"
                  value={inputs?.cvc}
                  label="CVC"
                  variant="outlined"
                  placeholder="CVC"
                  fullWidth
                  inputProps={{
                    maxLength: 4,
                  }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-12 text-end">
                <button className="small-contained-button">
                  {isLoading ? "Loading..." : "Add Card"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HandleChangeCard;
