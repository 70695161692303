import React, { useEffect, useState } from "react";
import GoalGallerySlider from "../../components/GoalStatement/GoalGallerySlider";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress, Divider } from "@mui/material";
import { GoalStatementListApi } from "src/DAL/GoalStatement/GoalStatementList";
import { s3baseUrl } from "src/config/config";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function SelfImage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [selfImageData, setSelfImageData] = useState({});

  const getGoalStatementList = async () => {
    const result = await GoalStatementListApi("self_image_gallery");
    if (result.code === 200) {
      setSelfImageData(result);
      if (result.goal_statement_gallery.length > 0) {
        let galleryArray = [];
        result.goal_statement_gallery.map((gallery, index) => {
          galleryArray.push({
            original: s3baseUrl + gallery.thumbnail_1,
            thumbnail: s3baseUrl + gallery.thumbnail_2,
          });
        });
        setGoalStatementGallery(galleryArray);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/self-image/previous-histories`);
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <PageDescription
        parameter="self_image_description"
        else_title="Self Image"
      />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 mt-4">
              <Divider />
            </div>
            <div className="col-12 mt-4 text-end">
              <button
                className="small-contained-button "
                type="button"
                onClick={() => navigate(`/self-image/manage-photos`)}
              >
                Manage Photos
              </button>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {goalStatementGallery.length > 0 && (
        <div className="row mb-md-5">
          <GoalGallerySlider data={goalStatementGallery} />
        </div>
      )}
      <div className="col-12">
        <div className="text-end">
          {selfImageData.is_history_available && (
            <button
              className="small-contained-button mb-3"
              onClick={handleNavigate}
            >
              Previous Self-Images
            </button>
          )}
        </div>
        <GeneralQuestionsList
          created_for="self_image"
          remove_back_button={true}
          check_user={true}
          heading="Self Image Questions"
          is_inline={true}
        />
      </div>
    </div>
  );
}

export default SelfImage;
