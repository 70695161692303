import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  community_feed_action,
  delete_feed_action,
  get_feeds_detail,
  pin_feed_action,
} from "src/DAL/Community/Community";
import { Chip, Tooltip } from "@mui/material";
import { projectMode, s3baseUrl } from "src/config/config";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import { ReactVideoPlayer } from "src/components";
import ShowAllLikes from "./components/LikesPopup/ShowAllLikes";
import {
  count_chars,
  FEED_TXET_LIMIT,
  get_short_string,
  LONG_TEXT_LIMIT,
  post_description,
  replaceUserNamesWithHTML,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { FeedComments, VideoStream } from "./components";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CreatePost from "./CreatePost";
import UserInfoCard from "./components/LikesPopup/UserInfoCard";
import ActionsCount from "./components/LikesPopup/ActionsCount";
import HandleAction from "./components/LikesPopup/HandleAction";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import FeedImageSlider from "src/components/GoalStatement/FeedImageSlider";
import EventInfoForPost from "./components/LikesPopup/EventInfoForPost";
import PollFeed from "./components/FeedPoll/PollFeed";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import FeedPollResult from "./components/FeedPoll/FeedPollResult";
import SurveyFeed from "./components/SurveyFeed/SurveyFeed";
import FeedSurveyResult from "./components/SurveyFeed/FeedSurveyResult";

export default function FeedDetailPopup({
  handleCloseDetailBox,
  post_id,
  feed,
  getFeedsListingOnAddFeed,
  handleSuccessDelete,
  handleSuccessUpdate,
  feeds_type,
  event_id,
  openDetailModal,
  feedSetting,
  selectedImageIndex,
  is_detail_page,
  handleUpdatePoll,
}) {
  const settings = useContentSetting();
  const { handleAddChat } = useChat();
  const { socket } = settings;
  const [showTab, setShowTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [feedDetail, setFeedDetail] = useState(feed);
  const [isLiked, setIsLiked] = useState(feed.is_liked);
  const [isGratituded, setIsGratituded] = useState(feed.is_gratitude);
  const [topLikedUser, setTopLikedUser] = useState(feed.top_liked_user);
  const [feedType, setFeedType] = useState(feed.feed_type);
  const [topGratitudeUser, setTopGratitudeUser] = useState(
    feed.top_gratitude_user
  );
  const [likeCount, setLikeCount] = useState(feed.like_count);
  const [gratitudeCount, setGratitudeCount] = useState(feed.gratitude_count);
  const [formAction, setFormAction] = useState("EDIT");
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [isShowMore, setIsShowMore] = useState(true);
  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [coinsCount, setCoinsCount] = useState(feed.coins_count);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const [showGlow, setShowGlow] = useState(true);
  const [openPoll, setOpenPoll] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);

  //Changing tab values
  const getFeedsDeteil = async () => {
    const result = await get_feeds_detail(post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setCoinsCount(result.feeds.coins_count);
      setIsLiked(result.feeds.is_liked);
      setIsGratituded(result.feeds.is_gratitude);
      setTopLikedUser(result.feeds.top_liked_user);
      setLikeCount(result.feeds.like_count);
      setTopGratitudeUser(result.feeds.top_gratitude_user);
      setGratitudeCount(result.feeds.gratitude_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const handleOpenGratitudeBox = () => {
    setOpenLikesModal(true);
    setShowTab(2);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };

  const handleClose = () => {
    setOpenPoll(false);
    setOpenSurvey(false);
  };

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    } else if (action === "gratitude") {
      setIsGratituded(true);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);
    const result = await community_feed_action(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
        setCoinsCount(result.action_response.coins_count);
      } else if (action === "gratitude") {
        setTopGratitudeUser(result.action_response.top_gratitude_user);
        setGratitudeCount(result.action_response.gratitude_count);
        setCoinsCount(result.action_response.coins_count);
      }
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      } else if (action === "gratitude") {
        setIsGratituded(false);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFeedEdit = () => {
    setFormAction("EDIT");
    setOpenCreateModal(true);
  };

  const handleAgreePin = () => {
    setOpenPin(true);
  };

  const handleAgreeDelete = (value) => {
    setFeedId(value._id);
    setOpenDelete(true);
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feedId);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      setOpenDelete(false);
      handleSuccessDelete(feed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePinFeed = async () => {
    setOpenPin(false);
    const formData = new FormData();
    formData.append("feed", feedDetail._id);
    if (
      feedDetail.is_feature === false ||
      feedDetail.is_feature === "false" ||
      feedDetail.is_feature === undefined
    ) {
      formData.append("action", "feature");
    } else {
      formData.append("action", "unfeature");
    }
    const result = await pin_feed_action(formData);
    if (result.code === 200) {
      setFeedDetail((prev) => {
        return {
          ...prev,
          is_feature: !feedDetail.is_feature,
        };
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSendMessage = async (row) => {
    handleAddChat(row.action_info?.action_id);
  };

  let MENU_OPTIONS = [];
  if (settings.userInfo.is_super_executive || feedDetail?.is_self) {
    if (feedDetail.feed_type === "poll") {
      if (feedDetail.poll_info.poll_status !== "expired") {
        MENU_OPTIONS.push({
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleFeedEdit,
        });
      }
    } else if (feedDetail.feed_type === "survey") {
      if (feedDetail.survey_info.survey_status !== "expired") {
        MENU_OPTIONS.push({
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleFeedEdit,
        });
      }
    } else {
      MENU_OPTIONS.push({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleFeedEdit,
      });
    }
    MENU_OPTIONS.push({
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    });
  }
  if (settings.userInfo.is_super_executive) {
    MENU_OPTIONS.push({
      label: feedDetail.is_feature == false ? "Pin" : "Unpin",
      icon: "akar-icons:pin",
      handleClick: handleAgreePin,
    });
  }
  if (
    (settings.userInfo.is_super_executive || settings.userInfo.is_executive) &&
    settings.userInfo.is_chat_allow &&
    !openDetailModal &&
    feedDetail.action_info.action_id !== settings.userInfo._id
  ) {
    MENU_OPTIONS.push({
      label: "Message",
      icon: "material-symbols:send",
      handleClick: handleSendMessage,
    });
  }

  const handleUpdateFeed = (feed) => {
    setFeedDetail(feed);
    if (handleUpdatePoll) {
      handleUpdatePoll(feed);
    }
  };

  const handlePollDetail = (feed) => {
    setOpenPoll(true);
  };

  const handleSurveyDetail = (feed) => {
    setOpenSurvey(true);
  };

  useEffect(() => {
    setShowGlow(true);
    setTimeout(() => {
      setShowGlow(false); // Hide the image after 3 seconds
    }, 3000);
    getFeedsDeteil();
  }, [post_id]);

  useEffect(() => {
    // for close on escape
    const is_event_path = window.location.pathname.includes("live-events");

    if (is_event_path && event_id) {
      window.history.replaceState(
        null,
        null,
        `/live-events/${event_id}/feed-detail/${post_id}`
      );
    }

    if (!is_event_path) {
      window.history.replaceState(null, null, `/feed-detail/${post_id}`);
    }

    window.onkeyup = function (event) {
      if (event.keyCode == 27) {
        handleCloseDetailBox();
      }
    };

    return () => {
      if (is_event_path && event_id) {
        window.history.replaceState(null, null, `/live-events/${event_id}`);
      }
      if (!is_event_path) {
        window.history.replaceState(null, null, `/thesource`);
      }
    };
  }, []);

  const update_feed_likes_by_socket = (data) => {
    setLikeCount(data.action_response.like_count);
    setCoinsCount(data.action_response.coins_count);
    setTopLikedUser(data.action_response.top_liked_user);
    if (settings.userInfo._id == data.action_by) {
      setIsLiked(data.action_response.is_liked);
    }
  };

  const update_feed_gratitude_by_socket = (data) => {
    setTopGratitudeUser(data.action_response.top_gratitude_user);
    setGratitudeCount(data.action_response.gratitude_count);
    setCoinsCount(data.action_response.coins_count);
    if (settings.userInfo._id == data.action_by) {
      setIsGratituded(data.action_response.is_gratitude);
    }
  };

  const handle_poll_answered = (data) => {
    setFeedDetail((old) => {
      let poll_info = { ...old.poll_info };
      let selected_options = [];
      if (old.selected_options?.length > 0) {
        selected_options = [...old.selected_options];
      }
      if (old._id == data.feed_obj._id) {
        poll_info = data.feed_obj.poll_info;
        if (data.action_by._id === settings.userInfo._id) {
          selected_options = data.feed_obj.selected_options;
        }
      }
      return { ...old, poll_info, selected_options };
    });
  };

  const handle_poll_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedDetail((old) => {
        let poll_info = old.poll_info;
        poll_info.poll_status = "expired";
        return { ...old, poll_info };
      });
    }
  };

  const handle_survey_answered = (data) => {
    setFeedDetail((old) => {
      let survey_info = { ...old.survey_info };
      let selected_options = [];
      if (old.selected_options?.length > 0) {
        selected_options = [...old.selected_options];
      }
      if (old._id == data.feed_obj._id) {
        survey_info = data.feed_obj.survey_info;
        if (data.action_by._id === settings.userInfo._id) {
          selected_options = data.feed_obj.selected_options;
        }
      }
      return { ...old, survey_info, selected_options };
    });
  };

  const handle_survey_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedDetail((old) => {
        let survey_info = old.survey_info;
        survey_info.survey_status = "expired";
        return { ...old, survey_info };
      });
    }
  };

  useEffect(() => {
    socket.emit("live_event_room", "live_feed_room");
    if (openDetailModal || is_detail_page) {
      socket.on("live_feed_room_reciever", (data) => {
        console.log(data, "live_feed_room_reciever FeedDetailPopup");
        if (data.action === "feedlike" || data.action === "feedunlike") {
          update_feed_likes_by_socket(data);
        } else if (data.action === "gratitude") {
          update_feed_gratitude_by_socket(data);
        } else if (
          data.action === "add_comment_reply" ||
          data.action === "add_comment"
        ) {
          setFeedDetail((old) => {
            return {
              ...old,
              comment_count: old.comment_count + 1,
            };
          });
        } else if (
          data.action === "delete_comment_reply" ||
          data.action === "delete_comment"
        ) {
          setFeedDetail((old) => {
            return {
              ...old,
              comment_count: old.comment_count - 1,
            };
          });
        } else if (data.action === "poll_answered") {
          handle_poll_answered(data);
        } else if (data.action === "poll_expired") {
          handle_poll_expired(data);
        } else if (data.action === "survey_answered") {
          handle_survey_answered(data);
        } else if (data.action === "survey_expired") {
          handle_survey_expired(data);
        }
      });
    }

    return () => {
      socket.off("live_feed_room_reciever");
    };
  }, [openDetailModal, is_detail_page]);

  useEffect(() => {
    socket.on("end_stream_event_listener", (payload) => {
      if (payload.feed_id === post_id) {
        enqueueSnackbar("Stream ended.", { variant: "info" });
        handleCloseDetailBox();
      }
    });

    return () => {
      socket.off("end_stream_event_listener");
    };
  }, [post_id]);

  const getImages = (data) => {
    let galleryArray = [];
    let galleryObject = {};
    data.map((gallery, index) => {
      galleryObject = {
        original: s3baseUrl + gallery.thumbnail_1,
        thumbnail: s3baseUrl + gallery.thumbnail_1,
      };
      galleryArray.push(galleryObject);
    });
    return galleryArray;
  };

  return (
    <>
      <div className="row post-detail-box">
        {feedDetail.is_reward_feed &&
          showGlow &&
          feedDetail.reward_data?.reward_feed_gif && (
            <div className="gif-image">
              <img src={s3baseUrl + feedDetail.reward_data?.reward_feed_gif} />
            </div>
          )}
        {feedDetail?.feed_type === "general" ||
        (feedDetail.feed_images?.length <= 0 &&
          !feedDetail.video_url &&
          !feedDetail?.is_live_streaming &&
          !feedDetail.embed_code) ? (
          <div className="col-12 col-md-6 mt-5 mt-md-0 mx-auto profile-cards p-3 mb-3 main-section">
            <div className="d-flex w-100 justify-content-between">
              <UserInfoCard
                profile_image={
                  s3baseUrl + feedDetail?.action_info?.profile_image
                }
                user_name={htmlDecode(feedDetail?.action_info?.name)}
                avatar_char={feedDetail?.action_info?.name[0]}
                date={feedDetail?.createdAt}
                activity_type={null}
              />

              {
                <div className="poster-logo text-end">
                  {feeds_type == "general" ? (
                    <span className="d-flex feed-level">
                      <Tooltip
                        title={
                          feedSetting[
                            `${feedDetail.created_for_level_or_type}_title`
                          ]
                            ? feedSetting[
                                `${feedDetail.created_for_level_or_type}_title`
                              ]
                            : ""
                        }
                      >
                        <img
                          src={
                            feedSetting[
                              `${feedDetail.created_for_level_or_type}_badge`
                            ]
                              ? s3baseUrl +
                                feedSetting[
                                  `${feedDetail.created_for_level_or_type}_badge`
                                ]
                              : ""
                          }
                          className="dynamite-level-image"
                        />
                      </Tooltip>
                      {feedDetail.created_for_level_or_type == "win" &&
                        feedSetting.win_badge && (
                          <Tooltip
                            title={
                              feedSetting.win_title
                                ? feedSetting.win_title
                                : "Win"
                            }
                          >
                            <img
                              src={
                                feedSetting.win_badge
                                  ? s3baseUrl + feedSetting.win_badge
                                  : ""
                              }
                              className="dynamite-level-image"
                            />
                          </Tooltip>
                        )}
                      {(feedDetail?.is_self ||
                        settings.userInfo.is_super_executive ||
                        settings.userInfo.is_executive) &&
                        MENU_OPTIONS.length > 0 && (
                          <CustomPopover
                            menu={MENU_OPTIONS}
                            data={feedDetail}
                          />
                        )}
                    </span>
                  ) : (
                    <span className="d-flex feed-level">
                      {(feedDetail?.is_self ||
                        settings.userInfo.is_super_executive ||
                        settings.userInfo.is_executive) &&
                        MENU_OPTIONS.length > 0 && (
                          <CustomPopover
                            menu={MENU_OPTIONS}
                            data={feedDetail}
                          />
                        )}
                    </span>
                  )}
                </div>
              }
            </div>

            <div className="mt-2 post-description">
              <p
                dangerouslySetInnerHTML={{
                  __html: urlify(
                    count_chars(feedDetail.description) > FEED_TXET_LIMIT &&
                      isShowMore
                      ? post_description(
                          replaceUserNamesWithHTML(
                            feedDetail.description,
                            feedDetail.mentioned_users
                          ),
                          FEED_TXET_LIMIT
                        )
                      : replaceUserNamesWithHTML(
                          feedDetail.description,
                          feedDetail.mentioned_users
                        )
                  ),
                }}
              ></p>
              {count_chars(feedDetail.description) > FEED_TXET_LIMIT && (
                <span
                  className="ms-2"
                  onClick={() => {
                    setIsShowMore(!isShowMore);
                  }}
                >
                  {isShowMore ? "See More" : "See Less"}
                </span>
              )}
            </div>
            {feedDetail.feed_type === "poll" && feedDetail.poll_info && (
              <PollFeed
                feed={feedDetail}
                handleUpdateFeed={handleUpdateFeed}
                handlePollDetail={handlePollDetail}
                feedSetting={feedSetting}
              />
            )}
            {feedDetail.feed_type === "survey" && feedDetail.survey_info && (
              <SurveyFeed
                feed={feedDetail}
                handleUpdateFeed={handleUpdateFeed}
                handleSurveyDetail={handleSurveyDetail}
              />
            )}
            {feedDetail.event_info?.is_event_info && (
              <EventInfoForPost feed={feedDetail} />
            )}
            <ActionsCount
              handleOpenLikedBox={handleOpenLikedBox}
              likeCount={likeCount}
              topLikedUser={topLikedUser}
              handleOpenGratitudeBox={handleOpenGratitudeBox}
              gratitudeCount={gratitudeCount}
              topGratitudeUser={topGratitudeUser}
              coinsCount={coinsCount}
              feeds_type={feeds_type}
            />
            <hr />
            <HandleAction
              isLiked={isLiked}
              handleFeedAction={handleFeedAction}
              feeds_type={feeds_type}
              setShowAddComment={() => {}}
              isGratituded={isGratituded}
              feed_id={post_id}
            />
            <hr />
            <FeedComments
              is_popup={openDetailModal}
              feed_id={post_id}
              getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
              setTotalCommentCount={setTotalCommentCount}
              is_detail_page={is_detail_page}
              feed={feedDetail}
              event_id={event_id}
              feedSetting={feedSetting}
            />
          </div>
        ) : (
          <>
            {feedDetail.feed_type === "poll" && feedDetail.poll_info && (
              <PollFeed
                feed={feedDetail}
                handleUpdateFeed={handleUpdateFeed}
                handlePollDetail={handlePollDetail}
                feedSetting={feedSetting}
              />
            )}
            {feedDetail.feed_type === "survey" && feedDetail.survey_info && (
              <SurveyFeed
                feed={feedDetail}
                handleUpdateFeed={handleUpdateFeed}
                handleSurveyDetail={handleSurveyDetail}
              />
            )}
            {feedDetail.feed_type === "image" &&
              feedDetail.feed_images &&
              feedDetail.feed_images.length > 0 && (
                <div className="col-12 col-md-8 post-detail-image">
                  <div className="feed-image m-5">
                    <FeedImageSlider
                      data={getImages(feedDetail.feed_images)}
                      selectedIndex={selectedImageIndex}
                    />
                  </div>
                </div>
              )}
            {feedDetail?.feed_type === "video" && feedDetail?.video_url && (
              <div className="col-12 col-md-8 post-detail-image">
                <div className="feed-image m-5">
                  <ReactVideoPlayer
                    url={feedDetail?.video_url}
                    playingVideo={true}
                  />
                </div>
              </div>
            )}
            {feedDetail?.feed_type === "live" &&
              (projectMode === "DEV" ? (
                <div className="col-12 col-md-8 post-detail-image">
                  <div className="feed-image m-5">
                    {feedDetail?.is_live_streaming ? (
                      <VideoStream post_id={post_id} />
                    ) : (
                      <div className="feed-image">
                        <img src={s3baseUrl + feedDetail?.image?.thumbnail_1} />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                feedDetail?.embed_code && (
                  <div className="col-12 col-md-8 post-detail-image">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feedDetail?.embed_code,
                      }}
                      className="feed-image m-5"
                    ></div>
                  </div>
                )
              ))}
            {feedDetail?.feed_type === "embed_code" &&
              feedDetail?.embed_code && (
                <div className="col-12 col-md-8 post-detail-image">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: feedDetail?.embed_code,
                    }}
                    className="feed-image m-5"
                  ></div>
                </div>
              )}
            <div
              className={`col-12 col-md-4 profile-cards p-4 p-lg-3 mb-3 main-section mx-auto ${
                openDetailModal ? "popup-detail-box" : "detail-page-box"
              }`}
            >
              <div className="d-flex w-100 justify-content-between">
                <UserInfoCard
                  profile_image={
                    s3baseUrl + feedDetail?.action_info?.profile_image
                  }
                  user_name={htmlDecode(feedDetail?.action_info?.name)}
                  avatar_char={feedDetail?.action_info?.name[0]}
                  date={feedDetail?.createdAt}
                  activity_type={null}
                />

                {(feedDetail?.is_self ||
                  settings.userInfo.is_super_executive ||
                  settings.userInfo.is_executive) &&
                  MENU_OPTIONS.length > 0 && (
                    <div className="poster-logo text-end">
                      <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                    </div>
                  )}
              </div>
              <div className="mt-2 post-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: urlify(
                      count_chars(feedDetail.description) > FEED_TXET_LIMIT &&
                        isShowMore
                        ? post_description(
                            replaceUserNamesWithHTML(
                              feedDetail.description,
                              feedDetail.mentioned_users
                            ),
                            FEED_TXET_LIMIT
                          )
                        : replaceUserNamesWithHTML(
                            feedDetail.description,
                            feedDetail.mentioned_users
                          )
                    ),
                  }}
                ></p>
                {count_chars(feedDetail.description) > FEED_TXET_LIMIT && (
                  <span
                    className="ms-2"
                    onClick={() => {
                      setIsShowMore(!isShowMore);
                    }}
                  >
                    {isShowMore ? "See More" : "See Less"}
                  </span>
                )}
              </div>
              {feedDetail.event_info?.is_event_info && (
                <EventInfoForPost feed={feedDetail} />
              )}
              <ActionsCount
                handleOpenLikedBox={handleOpenLikedBox}
                likeCount={likeCount}
                topLikedUser={topLikedUser}
                handleOpenGratitudeBox={handleOpenGratitudeBox}
                gratitudeCount={gratitudeCount}
                topGratitudeUser={topGratitudeUser}
                coinsCount={coinsCount}
                feeds_type={feeds_type}
              />
              <hr />
              <HandleAction
                isLiked={isLiked}
                handleFeedAction={handleFeedAction}
                feeds_type={feeds_type}
                setShowAddComment={() => {}}
                isGratituded={isGratituded}
                feed_id={post_id}
              />
              <hr />
              <FeedComments
                is_popup={openDetailModal}
                feed_id={post_id}
                getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
                setTotalCommentCount={setTotalCommentCount}
                is_detail_page={is_detail_page}
                feed={feedDetail}
                event_id={event_id}
                feedSetting={feedSetting}
              />
            </div>
          </>
        )}

        {openLikesModal && (
          <ShowAllLikes
            openLikesModal={openLikesModal}
            setOpenLikesModal={setOpenLikesModal}
            handleCloseSimpleBox={handleCloseSimpleBox}
            showTab={showTab}
            post_id={post_id}
            feeds_type={feeds_type}
          />
        )}
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this post?"}
          handleAgree={handleAgree}
        />
      </div>
      {/* </Modal> */}
      <CustomConfirmation
        open={openPin}
        setOpen={setOpenPin}
        title={"Are you sure you want to pin this post?"}
        handleAgree={handlePinFeed}
      />
      <GeneralModelBox
        open={openPoll}
        setOpen={setOpenPoll}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedPollResult selectedFeed={feed} handleClosePoll={handleClose} />
        }
      />
      <GeneralModelBox
        open={openSurvey}
        setOpen={setOpenSurvey}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedSurveyResult
            selectedFeed={feed}
            handleCloseSurvey={handleClose}
          />
        }
      />
      {setOpenCreateModal && (
        <CreatePost
          setFeedType={setFeedType}
          feedType={feedType}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          getFeedsListing={getFeedsListingOnAddFeed}
          userInfo={settings.userInfo}
          selectedFeed={feed}
          formAction={formAction}
          setFormAction={setFormAction}
          handleSuccessUpdate={handleSuccessUpdate}
          feeds_type={feeds_type}
          event_id={event_id}
          feedSetting={feedSetting}
        />
      )}
    </>
  );
}
