import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../../../bl_libs/invokeApi";
export const AddNote = async (data) => {
  const requestObj = {
    path: `api/lesson_note/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditNote = async (id, data) => {
  const requestObj = {
    path: `api/lesson_note/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteNote = async (id) => {
  const requestObj = {
    path: `api/lesson_note/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
