import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const memoriesListing = async (loadMore, data) => {
  const requestObj = {
    path: `${loadMore}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddMemoriesApi = async (data) => {
  const requestObj = {
    path: `api/moment/add_magic_moment/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const UploadMagicMomentImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/moment/update_magic_moment_image_on_s3/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteMagicMomentImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/moment/delete_magic_moment_image_from_s3/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteMomentdeApi = async (moment_slug) => {
  const requestObj = {
    path: `api/moment/delete_magic_moment/${moment_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditMomentApi = async (moment_slug, data) => {
  const requestObj = {
    path: `api/moment/update_magic_moment/${moment_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const MomentDetailApi = async (moment_slug) => {
  const requestObj = {
    path: `api/moment/detail_magic_moment/${moment_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
