import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useRef, useCallback } from "react";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import {
  add_feed_comment,
  delete_comment_api,
  edit_feed_comment,
  get_feeds_commnets,
} from "src/DAL/Community/Comments";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { SingleComment } from "..";
import EmojiPicker from "src/components/GeneralComponents/EmojiPicker";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { s3baseUrl } from "src/config/config";
import MentionEditor from "../MentionEditor";
import { decryptToken } from "src/utils/constants";

function FeedComments(props) {
  const settings = useContentSetting();
  const { socket } = settings;
  const commentFormRef = useRef(null);
  const mentionEditorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const {
    feed_id,
    setTotalCommentCount,
    is_popup,
    is_detail_page,
    feed,
    event_id,
    feedSetting,
  } = props;

  const [comments, setComments] = useState([]);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [updateValue, setUpdateValue] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [previews, setPreviews] = useState("");
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/feeds/list_comment_with_pagination/v1?page=0&limit=10"
  );
  // default
  let api_path = "api/feeds/list_comment_with_pagination/v1?page=0&limit=10";

  // comments add / edit
  const [formAction, setFormAction] = useState("ADD");
  const [commentInput, setCommentInput] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef(null);
  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMore, observer]
  );

  const handleRemove = () => {
    setPreviews("");
    setOldImage("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleCommentEdit = (selected_comment) => {
    setSelectedComment(selected_comment);
    setCommentInput(selected_comment.message);
    if (selected_comment.image?.thumbnail_2) {
      setOldImage(selected_comment.image?.thumbnail_2);
    }
    setUpdateValue(selected_comment);
    setMentionedUsers(selected_comment.mentioned_users);
    setFormAction("EDIT");
    commentFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCommentDelete = (selected_comment) => {
    setOpenDelete(true);
    setSelectedComment(selected_comment);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_comment_api(selectedComment._id);
    if (result.code === 200) {
      const socketData = {
        action: "delete_comment",
        feed_id: feed_id,
        comment: selectedComment._id,
        token: decryptToken(localStorage.getItem("token")),
        action_response: result.action_response,
        creator_id: result.action_response.creator_id,
      };

      socket.emit("feed_room_action_event", socketData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCancelUpdate = (e) => {
    e.preventDefault();
    setImage("");
    mentionEditorRef.current?.clearContent();
    setPreviews("");
    setOldImage("");
    setCommentInput("");
    setFormAction("ADD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const formData = new FormData();
    formData.append("feed", feed_id);
    formData.append("message", commentInput);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));
    if (image) {
      formData.append("image", image);
    }
    const result = await add_feed_comment(formData);
    if (result.code === 200) {
      const socketData = {
        action: "add_comment",
        feed_id: feed_id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };

      socket.emit("feed_room_action_event", socketData);
      handleRemove();
      setCommentInput("");
      mentionEditorRef.current?.clearContent();
      setShowEmojiPicker(false);
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const formData = new FormData();
    formData.append("message", commentInput);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));
    if (selectedComment.image?.thumbnail_2 && !oldImage) {
      formData.append("is_image_deleted", true);
    }
    if (image) {
      formData.append("image", image);
    }
    const result = await edit_feed_comment(formData, selectedComment._id);
    if (result.code === 200) {
      const socketData = {
        action: "edit_comment",
        feed_id: feed_id,
        comment: selectedComment._id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
      setCommentInput("");
      mentionEditorRef.current?.clearContent();
      handleRemove();
      setShowEmojiPicker(false);
      setIsLoadingForm(false);
      setFormAction("ADD");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const getFeedsComments = async (type) => {
    const formData = new FormData();
    formData.append("feed_id", feed_id);
    setIsLoadingMore(true);

    if (type === "next") {
      api_path = loadMore;
    }

    const result = await get_feeds_commnets(api_path, formData);
    if (result.code === 200) {
      if (type === "next") {
        var newArray = comments.concat(result.comment);
        setComments(newArray);
      } else {
        setComments(result.comment);
      }

      setPageNumber(pageNumber + 1);
      setTotalPages(result.total_pages);
      setLoadMore(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    getFeedsComments("next");
  };

  useEffect(() => {
    getFeedsComments("all");
  }, [feed_id]);

  useEffect(() => {
    let count = comments.length;
    comments.map((comment, i) => {
      count = count + comment?.child_comment?.length;
    });
    setTotalCommentCount(count);
  }, [comments]);

  const update_comment_likes_by_socket = (data) => {
    setComments((comments) => {
      const comment_list = comments.map((comment) => {
        let action_comment = data.action_response.comment;
        if (data.action_response.parent_comment && comment?.child_comment) {
          let child_comments = comment.child_comment.map((c_comment) => {
            if (c_comment._id == action_comment) {
              c_comment.like_count = data.action_response.comment_like_count;
              if (settings.userInfo._id == data.action_by) {
                c_comment.is_liked = data.action_response.is_liked;
              }
            }
            return c_comment;
          });
          comment.child_comment = child_comments;
        } else if (comment._id == action_comment) {
          comment.like_count = data.action_response.comment_like_count;
          if (settings.userInfo._id == data.action_by) {
            comment.is_liked = data.action_response.is_liked;
          }
        }
        return comment;
      });
      return comment_list;
    });
  };

  const add_comment_by_socket = (data) => {
    if (data.feed_id !== feed_id) {
      return;
    }
    let new_comment = data.action_response.comment;
    new_comment.comment_is_self = false;
    if (settings.userInfo._id == data.action_by) {
      new_comment.comment_is_self = true;
    }
    setComments((comments) => [new_comment, ...comments]);
  };

  const edit_comment_by_socket = (data) => {
    setComments((comments) => {
      const comment_list = comments.map((comment) => {
        let new_comment = data.action_response.comment;
        if (comment._id === new_comment._id) {
          new_comment.comment_is_self = false;
          if (settings.userInfo._id == data.action_by) {
            new_comment.comment_is_self = true;
          }
          return new_comment;
        }

        return comment;
      });
      return comment_list;
    });
  };

  const delete_comment_by_socket = (data) => {
    setComments((comments) => {
      const comment_list = comments.filter(
        (comment) => comment._id !== data.comment
      );
      return comment_list;
    });
  };

  const add_comment_reply_by_socket = (data) => {
    setComments((comments) => {
      console.log(comments, "commentscommentscommentscomments");
      const comment_list = comments.map((comment) => {
        if (comment._id == data.action_response.parent_comment) {
          let child_comments = [
            data.action_response.comment,
            ...comment.child_comment,
          ];
          comment.child_comment = child_comments.map((c_comment) => {
            if (
              settings.userInfo._id == c_comment.user_info_action_for.action_id
            ) {
              c_comment.comment_is_self = true;
            } else {
              c_comment.comment_is_self = false;
            }
            return c_comment;
          });
          comment.child_comments_count++;
        }
        return comment;
      });
      return comment_list;
    });
  };

  const edit_comment_reply_by_socket = (data) => {
    setComments((comments) => {
      const comment_list = comments.map((comment) => {
        let new_comment = data.action_response.comment;
        if (comment._id == new_comment.parent_comment) {
          let child_comments = comment.child_comment;
          if (child_comments?.length > 0) {
            child_comments = child_comments.map((child_comment) => {
              if (child_comment._id == new_comment._id) {
                child_comment = new_comment;
                let comment_is_self = false;
                if (settings.userInfo._id == data.action_by) {
                  comment_is_self = true;
                }
                child_comment.comment_is_self = comment_is_self;
              }
              return child_comment;
            });
          }
          comment.child_comment = child_comments;
        }
        return comment;
      });
      return comment_list;
    });
  };

  const delete_comment_reply_by_socket = (data) => {
    console.log(data, "called ");
    setComments((comments) => {
      const comments_list = comments.map((comment) => {
        if (comment._id === data.action_response.comment?._id) {
          const array_filtered = comment.child_comment.filter(
            (child_comment) => child_comment._id !== data.comment
          );
          comment.child_comment = array_filtered;
          comment.child_comments_count = comment.child_comments_count - 1;
        }
        return comment;
      });
      return comments_list;
    });
  };

  useEffect(() => {
    // socket.emit("live_event_room", "live_feed_room");
    socket.on("live_feed_room_reciever", (data) => {
      if (data.action === "commentlike" || data.action === "commentunlike") {
        update_comment_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        add_comment_by_socket(data);
      } else if (data.action === "edit_comment") {
        edit_comment_by_socket(data);
      } else if (data.action === "delete_comment") {
        delete_comment_by_socket(data);
      } else if (data.action === "add_comment_reply") {
        add_comment_reply_by_socket(data);
      } else if (data.action === "edit_comment_reply") {
        edit_comment_reply_by_socket(data);
      } else if (data.action === "delete_comment_reply") {
        delete_comment_reply_by_socket(data);
      }
    });

    return () => {
      socket.off("live_feed_room_reciever");
    };
  }, [feed_id]);

  if (isLoading) {
    return (
      <div className="text-center">
        <CircularProgress
          className="mt-3"
          style={{ width: 20, height: 20 }}
          color="primary"
        />
      </div>
    );
  }

  return (
    <>
      <div className="profile-comments profile-main-comments">
        {comments.length > 0 &&
          comments.map((comment, index) => {
            return (
              <SingleComment
                comment={comment}
                is_popup={is_popup}
                setComments={setComments}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
                index={index}
                is_detail_page={is_detail_page}
                handleUpdateSpecificFeed={() => {
                  getFeedsComments("all");
                }}
                event_id={event_id}
                feed={feed}
                feedSetting={feedSetting}
                parent_comment={comment}
              />
            );
          })}

        {totalPages > pageNumber ? (
          <div className="view-comment mt-2 text-end me-3 date-color">
            {isLoadingMore ? (
              <span onClick={loadMoreData}>Loading...</span>
            ) : (
              <span
                ref={lastBookElementRef}
                onClick={loadMoreData}
                id="load-more-comments"
              >
                View more comments
              </span>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="new-memories mt-2 add-comment-box" ref={commentFormRef}>
        <form onSubmit={formAction === "ADD" ? handleSubmit : handleUpdate}>
          <MentionEditor
            ref={mentionEditorRef}
            setInputs={setCommentInput}
            setMentionedUsers={setMentionedUsers}
            mentionedUsers={mentionedUsers}
            feedLevel={feed?.created_for_level_or_type}
            placeholder={`Write a comment…`}
            event_id={event_id}
            editorClass="comment-editor mt-2"
            addPosition={{ top: 15, left: 15 }}
            value={commentInput}
            updateValue={updateValue}
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mt-3 mb-2 picker-empty-div"></div>

              {previews || oldImage ? (
                <span className="preview-comment-image mt-2 ms-2">
                  <span onClick={handleRemove}>x</span>
                  {previews ? (
                    <img src={previews} />
                  ) : (
                    <img src={s3baseUrl + oldImage} />
                  )}
                </span>
              ) : (
                <>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="message-chat-upload-button"
                    style={{ display: "none" }}
                    name=""
                    onChange={handleUpload}
                  />
                  <label>
                    <Tooltip title="Add Image" placement="top">
                      <IconButton
                        onClick={() => {
                          document.getElementById(
                            "message-chat-upload-button"
                          ) &&
                            document
                              .getElementById("message-chat-upload-button")
                              .click();
                        }}
                        className="upload-image-icon-comment mb-2"
                      >
                        <AddPhotoAlternateIcon />
                      </IconButton>
                    </Tooltip>
                  </label>
                </>
              )}
            </div>
            <div className="text-end mt-3">
              {formAction === "ADD" && (
                <button
                  className="comment-submit-button"
                  disabled={isLoadingForm}
                >
                  {isLoadingForm ? "Saving..." : "Post Comment"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 comment-submit-button"
                    onClick={handleCancelUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    className="comment-submit-button"
                    type="submit"
                    disabled={isLoadingForm}
                  >
                    {isLoadingForm ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </div>
          {showEmojiPicker && (
            <div className="col-12 mt-4 mt-lg-0 ms-auto">
              <EmojiPicker inputRef={inputRef} setInput={setCommentInput} />
            </div>
          )}
        </form>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this commment?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

export default FeedComments;
