import { Icon } from "@iconify/react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { get_root_value } from "src/utils/domUtils";

function LiveStreamSettings({ zmClient, mediaStream }) {
  const [activeCam, setActiveCam] = useState(null);
  const [activeMic, setActiveMic] = useState(null);

  const [cameraList, setCameraList] = useState([]);
  const [micList, setMicList] = useState([]);

  // stream.getMicList();
  // stream.getActiveCamera();

  console.log(mediaStream?.getCameraList(), "getCameraList");
  console.log(mediaStream?.getMicList(), "getMicList");
  console.log(mediaStream?.getActiveCamera(), "getActiveCamera");

  const handleSwitchCamera = (id) => {
    console.log("handleSwitchCamera");
    mediaStream.switchCamera(id);
    update_devices_data();
  };

  const handleSwitchMicrophone = (id) => {
    console.log("handleSwitchMicrophone");
    mediaStream.switchMicrophone(id);
    update_devices_data();
  };

  const update_devices_data = () => {
    const cam_list = mediaStream.getCameraList();
    const mic_list = mediaStream.getMicList();
    const active_cam = mediaStream.getActiveCamera();
    const active_mic = mediaStream.getActiveMicrophone();

    setCameraList(cam_list);
    setMicList(mic_list);
    setActiveCam(active_cam);
    setActiveMic(active_mic);
  };

  useEffect(() => {
    if (mediaStream) {
      update_devices_data();
    }
  }, []);

  return (
    <div className="row w-100 p-3">
      <div className="col-12 mt-2">
        <FormControl fullWidth size="small">
          <InputLabel shrink={true} id="cam-list">
            Camera Devices
          </InputLabel>
          <Select
            labelId="cam-list"
            id="demo-simple-select"
            label="Camera Devices"
            onChange={(e) => handleSwitchCamera(e.target.value)}
            value={activeCam}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="default">Default</MenuItem>
            {cameraList.map((cam) => (
              <MenuItem value={cam.deviceId}>{cam.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="col-12 mt-3">
        <FormControl fullWidth size="small">
          <InputLabel shrink={true} id="mic-list">
            Microphone Devices
          </InputLabel>
          <Select
            labelId="mic-list"
            id="demo-simple-select"
            label="Microphone Devices"
            onChange={(e) => handleSwitchMicrophone(e.target.value)}
            value={activeMic}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            {micList.map((mic) => (
              <MenuItem value={mic.deviceId}>{mic.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default LiveStreamSettings;
