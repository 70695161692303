import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const questions_listing_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/get_configration`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_answers_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/add_comment_on_questionare`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_specific_answers_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/add_comment_on_questionare/specific`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
