import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import CatagoriesSlider from "./CatagoriesSlider";

const CatagoriesSliderMain = ({ categories, eventDetail }) => {
  var data_length = categories.length;
  let set_infinite = false;
  if (data_length < 6) {
    set_infinite = false;
  }
  var settings = {
    dots: false,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 5,
    // autoplay: true,
    slidesToScroll: 1,
    swipeToSlide: true, // Allow users to scroll by swiping
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="event-slider community-slider">
      {categories.map((value, index) => (
        <CatagoriesSlider card={value} eventDetail={eventDetail} />
      ))}
    </Slider>
  );
};
export default CatagoriesSliderMain;
