import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const GoalStatementListApi = async (type) => {
  const requestObj = {
    path: `api/member/get_goal_statement?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UploadGalleryImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/upload_gallery_images/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddGalleryImage = async (data, type) => {
  const requestObj = {
    path: `api/member/update_goal_statement_gallery?type=${type ? type : ""}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteGalleryImage = async (data, type) => {
  const requestObj = {
    path: `api/goal_statement_question/delete_goal_statement_image_from_s3?type=${
      type ? type : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddSingleAnswerApi = async (data) => {
  const requestObj = {
    path: `api/member/update_goal_statement_question/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddAllAnswersApi = async (data) => {
  const requestObj = {
    path: `api/member/update_all_goal_statement_question/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
