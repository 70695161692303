import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

const OrderHistorysDetail = ({ orderObject }) => {
  console.log(orderObject, "orderObjectorderObjectorderObject");

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-12 d-flex mb-3"></div>
          </div>
          <div
            className="cart over_flow "
            style={{ width: "100%", overflow: "auto" }}
          >
            <table className="w-100 table-main ">
              <thead className=" cart__row ">
                <tr>
                  <th colSpan={2} className="text-center">
                    Product
                  </th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>

              <tbody>
                {orderObject.products && orderObject.products.length ? (
                  orderObject.products.map((item, index) => {
                    return (
                      <>
                        <tr className="cart__row cart-flex cart__border">
                          <td className="cart__image-wrapper cart-flex-item border-cart">
                            <a>
                              {item?.product_id?.image ? (
                                <img
                                  src={
                                    s3baseUrl +
                                    item?.product_id?.image[0]?.image
                                  }
                                  className="cart__image-wrapper "
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </a>
                          </td>
                          <td className="cart__meta small--text-left cart-flex-item border-cart">
                            <div className="slider-vid xmark__icon pointer"></div>
                            <div className="list-view-item__title word___wrap">
                              <h6
                                class="d-inline"
                                style={{ color: "white", marginTop: "2px" }}
                              >
                                {item.product_id ? item.product_id.name : "N/A"}
                              </h6>
                            </div>
                          </td>
                          <td className="cart__price-wrapper small--hide cart-flex-item border-cart">
                            <div className="product-price text-center">
                              <span className="price ">
                                £{item?.price?.toFixed(2)}
                              </span>
                            </div>
                          </td>
                          <td className="cart__update-wrapper cart-flex-item text-right  border-cart">
                            <div className="col-12  d-flex justify-content-center mt-2 mb-3 text-center detail-item-quantity">
                              {item.quantity}
                            </div>
                          </td>
                          <td className=" text-center small--hide border-cart">
                            <h6>£{(item.quantity * item.price).toFixed(2)}</h6>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr className="cart__row cart-flex cart__border pb-2 pt-2 text-center">
                    <td
                      colSpan={6}
                      className="border-cart"
                      style={{ fontSize: "18px" }}
                    >
                      {" "}
                      No Item Found
                    </td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr className="cart__border">
                  <td colSpan={6} className="text-end border-cart">
                    <div className="">
                      <div className="container">
                        <div className="row ">
                          <div className="col-lg-9 col-md-6 col-sm-12"></div>
                          <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                            {" "}
                            <span style={{ color: "#f6bd4b" }}>Sub Total</span>
                            <span
                              style={{ color: "#f6bd4b", paddingRight: "8px" }}
                            >
                              £{orderObject.sub_total.toFixed(2)}
                            </span>
                          </div>
                        </div>{" "}
                        {orderObject.discount > 0 && (
                          <div className="row ">
                            <div className="col-lg-9 col-md-6 col-sm-12"></div>
                            <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                              {" "}
                              <span style={{ color: "#f6bd4b" }}>Discount</span>
                              <span
                                style={{
                                  color: "#f6bd4b",
                                  paddingRight: "8px",
                                }}
                              >
                                £{orderObject.discount.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="row ">
                          <div className="col-lg-9 col-md-6 col-sm-12"></div>
                          <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                            {" "}
                            <span style={{ color: "#f6bd4b" }}>
                              Shipping Fees
                            </span>
                            <span
                              style={{
                                color: "#f6bd4b",
                                paddingRight: "8px",
                              }}
                            >
                              £{orderObject.shipping_charges.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        {orderObject.tax_info && (
                          <div className="row ">
                            <div className="col-lg-9 col-md-6 col-sm-12"></div>
                            <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                              <span style={{ color: "#f6bd4b" }}>
                                {htmlDecode(
                                  orderObject.tax_info.tax_type_title
                                )}
                              </span>
                              <span
                                style={{
                                  color: "#f6bd4b",
                                  paddingRight: "8px",
                                }}
                              >
                                £{orderObject.tax_charges.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="row ">
                          <div className="col-lg-9 col-md-6 col-sm-12"></div>
                          <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                            <span style={{ color: "#f6bd4b" }}>
                              Grand Total
                            </span>
                            <span
                              style={{
                                color: "#f6bd4b",
                                paddingRight: "8px",
                              }}
                            >
                              £
                              {Number(
                                orderObject?.total - +orderObject.discount
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="row ">
                          <div className="col-lg-9 col-md-6 col-sm-12"></div>
                          <div className="col-lg-3 col-md-6 col-sm-8 d-flex justify-content-between ">
                            <span style={{ color: "#f6bd4b" }}>
                              Paid Amount
                            </span>
                            <span
                              style={{
                                color: "#f6bd4b",
                                paddingRight: "8px",
                              }}
                            >
                              {convertCurrencyToSign(
                                orderObject.other_info?.converted_currency
                              )}
                              {Number(
                                orderObject.other_info?.converted_total
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderHistorysDetail;
